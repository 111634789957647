import { DivContainer50 } from "common/components/divContainer";
import AYPAInput from "common/components/input";
import { setCycleCount } from "store/slices/settings-slice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";

interface Props{
    isLoadingEnabled: boolean,
    isEntitySelected: boolean
}
export const CycleCount = ({ isLoadingEnabled, isEntitySelected }: Props) => {

    const [hasError, setHasError] = useState(false);

    /**Selectors */
    const cycleCount = useSelector((store: ApplicationState) => store.settingsReducer.cycleCount);

    /**Contanst */
    const dispatch = useDispatch();
    const labelTitle = "Cycles Per Day";
    const config = { type: 'number', id: 'cycleCount', placeholder: '1.0', min: '0.01', max: '2.0', step: '0.01' };
    const unit = "";
    const errorMessage = "Value must be between 0 and 2";
    const defaultValue = 1.0;

    const initValue = cycleCount ? cycleCount : defaultValue;

    /**Callback */
    const handlerOnChangeCycleCount = (data: any) => {
        const num_data = Number(data);
        if (0 >= num_data || num_data > 2.0) {
            setHasError(true)
            dispatch(setCycleCount(null))
        } else {
            setHasError(false)
            dispatch(setCycleCount(Number(data)));
        }
    }

    const onKeyPressHandler = () => { }

    return (
        <DivContainer50>
            <AYPAInput
                config={config}
                label={labelTitle}
                unit={unit}
                disabled={!isEntitySelected || isLoadingEnabled}
                onChangeCallback={handlerOnChangeCycleCount}
                onKeyPressCallback={onKeyPressHandler}
                hasError={hasError}
                errorMessage={errorMessage}
                initalValue={initValue}
            />
        </DivContainer50>
    )
}

import { ApplicationState } from "store";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LineData } from "common/interfaces/lineTypes";
import AYPALineChart from "common/components/lineChart";
import { setSimulationImage } from "store/slices/settings-slice";
import { getConfig, formatPerfectForesightSum } from "common/components/lineChart/utils";
import { ForesightRevenueData } from "common/interfaces/perfectForesightData";

interface Props {
    group: string
}
export const RevenuesChart = (props: Props) => {

    /**Constants */
    const dispatch = useDispatch();
    let mode: string = '';
    const mounted = useRef(true);
    /**States */
    const [lineData, setLineData] = useState<Array<LineData>>();
    const [customLabel, setCustomLabel] = useState<Array<number>>();

    /**Selectors */
    const perfectForesightId = useSelector((store: ApplicationState) => store.chartReducer.perfectForesightId);
    const perfectForesightSumData = useSelector((store: ApplicationState) => store.settingsReducer.perfectForesightSumData);
    const simulationChartBase64Image = useSelector((store: ApplicationState) => store.settingsReducer.simulationChartBase64Image);

    const generate_labels = (group: string) => {
        switch (group) {
            case 'year':
                mode = 'year'
                return { mainLabel: perfectForesightSumData?.yearly.map((item) => item.year), subLabel: 'Year' }
            case 'wint_sum':
                mode = 'barGraph'
                return { mainLabel: ['Winter', 'Summer'], subLabel: 'Season' }
            case 'month':
                mode = ''
                return { mainLabel: perfectForesightSumData?.monthly.map((item) => item.month), subLabel: 'Month' }
            case 'yearly_monthly':
                mode = 'month_pf'
                return { mainLabel: customLabel, subLabel: 'Year' }
            case 'yearly_wint_sum':
                mode = 'wint_sum_pf'
                return { mainLabel: customLabel, subLabel: 'Year' }
            default:
                return { mainLabel: [], subLabel: '' }
        }
    }

    const chartLabels = perfectForesightId ? generate_labels(props.group) : { mainLabel: [], subLabel: '' };

    const onCompleteCallback = (chart: any) => {
        // can't download image with multiple charts - !mode
        if (chart?.chart && !mode && !simulationChartBase64Image) {
            dispatch(setSimulationImage(chart.chart.toBase64Image()));
            chart.chart.update();
        }
    }

    const onCompleteFetchForesightData = ({ data }: any) => {
        if (data) {
            let data_array = Array<ForesightRevenueData>()
            let temp_group: any = [];

            switch (props.group) {
                case "total":
                    data_array = [data.total]
                    break;
                case "year":
                    data_array = data.yearly
                    temp_group = ['year']
                    break;
                case "wint_sum":
                    data_array = data.wint_sum
                    temp_group = ['wint_sum']
                    break;
                case "month":
                    data_array = data.monthly
                    temp_group = ['month']
                    break;
                case "yearly_monthly":
                    data_array = data.yearly_monthly
                    temp_group = ['year', 'month']
                    break;
                case "yearly_wint_sum":
                    data_array = data.yearly_wint_sum
                    temp_group = ['year', 'wint_sum']
                    break;
            }
            const { lineData, years_label } = formatPerfectForesightSum(data_array, temp_group);
            const custom_label: number[] = Array.from(years_label).sort((a, b) => a - b);
            setLineData(lineData);
            setCustomLabel(custom_label)
        }
    }

    useEffect(() => {
        if (perfectForesightId) {
            onCompleteFetchForesightData({ data: perfectForesightSumData });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perfectForesightId])


    useEffect(() => {
        return () => { mounted.current = false }
    }, []);

    return (
        <AYPALineChart
            config={getConfig('USD', chartLabels.subLabel, true, '', 'Cycles')}
            data={lineData}
            mode={mode}
            onCompleteCallback={() => onCompleteCallback}
            labels={chartLabels?.mainLabel}
        />
    )
}

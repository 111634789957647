import { useState } from "react";
import classes from "./input.module.scss";

interface Props {
    config?: any,
    label?: string,
    disabled: boolean,
    unit: string,
    onChangeCallback: Function,
    onKeyPressCallback: Function,
    hasError?: boolean
    errorMessage?: string,
    initalValue?: any
}
const AYPA_Input = ({ config, label, disabled, unit, onChangeCallback, onKeyPressCallback, hasError, errorMessage, initalValue }: Props) => {

    const [inputValue, setInputValue] = useState(initalValue ? initalValue : '');

    const onChangeHandler = (event: any) => {
        const { value } = event.target;
        setInputValue(value);
        onChangeCallback(value);
    };


    const errorClass = () => hasError ?? false ? classes['container--red'] : '';

    return (
        <>
            {label &&
                <>
                    <label htmlFor={config.id}>{label}</label> 
                </>
            }
            <fieldset className={`${classes.container} ${errorClass()}`} disabled={disabled} >
                <input {...config}
                    name={config.id}
                    onChange={onChangeHandler}
                    value={inputValue}
                    autoComplete='false'
                    onKeyDown={onKeyPressCallback}
                />
                <span>{unit}</span>
            </fieldset>
            { hasError && <div className={`${errorClass()}`}>{errorMessage}</div>}
            
            
        </>
    )
}

export default AYPA_Input;

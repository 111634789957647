import { useSelector } from 'react-redux';
import { ApplicationState } from "store";
import { SourceTypes } from '../../../store/types/source-types';
import { SourceSelector } from 'common/aypaForms/sourceSelector';
import { Historical } from './historical';
import { BulkTbSettings } from './bulkTb';


export const BulkSettingsSection = () => {
    const isPriceLoading = useSelector((store: ApplicationState) => store.pricesReducer.isLoadingChart);
    const isSimulateLoading = useSelector((store: ApplicationState) => store.chartReducer.isLoading);
    const settings = useSelector((store: ApplicationState) => store.settingsReducer);
    const isLoading = isPriceLoading || isSimulateLoading;


    // NRG
    // select Source then select ISO
    // show list of prices
    // filter DA and RT
    // filter Hour, 5min, 5min hourly
    // have type ahead for price
    return (
        <>
            <div className="scrollable scrollable-y">
                <SourceSelector isLoadingEnabled={isLoading} />
                {settings.sourceId === SourceTypes.NRG_STREAM &&
                    (<>
                        <Historical />
                    </>)
                }
                {/* {settings.sourceId === SourceTypes.AYPA_ZONAL &&
                    (<>
                        <Zonal isLoadingEnabled={isLoading} showLocation={true} />
                    </>)
                }
                {settings.sourceId === SourceTypes.AYPA_NODAL &&
                    (<>
                        <Nodal isLoadingEnabled={isLoading} showLocationAndNode={true} />
                    </>)
                }
                {settings.sourceId === SourceTypes.WOOD_MAC &&
                    (<>
                        <WoodMac isLoadingEnabled={isLoading} showLocation={true} />
                    </>)
                } */}
                <BulkTbSettings></BulkTbSettings>
                
            </div>
        </>
    )
}

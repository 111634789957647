import { Table } from "reactstrap";
import { Loading } from "../../../../../common/components/loading";
import { ApplicationState } from "store";
import { useSelector } from "react-redux";
import { ForesightRevenueData, SumForesightData } from "common/interfaces/perfectForesightData";
import { useEffect, useState } from "react";
import { formatCurrency } from "common/utils/utils";
import { wint_sum } from "common/components/lineChart/values";


interface tableProps {
    group: string
}
export const RevenuesTable = ({ group }: tableProps) => {
    const errorMessage = useSelector((store: ApplicationState) => store.chartReducer.errorMessage);
    const isLoading = useSelector((store: ApplicationState) => store.chartReducer.isLoading);
    const perfectForesightSumData = useSelector((store: ApplicationState) => store.settingsReducer.perfectForesightSumData);
    const perfectForesightId = useSelector((store: ApplicationState) => store.chartReducer.perfectForesightId);

    const [revenues, setRevenues] = useState<Array<ForesightRevenueData>>();

    const onCompleteFetchForesightData = (data: SumForesightData | null) => {
        if (data) {
            switch (group) {
                case "total":
                    setRevenues([data.total])
                    break;
                case "yearly":
                    setRevenues(data.yearly)
                    break;
                case "wint_sum":
                    setRevenues(data.wint_sum)
                    break;
                case "monthly":
                    setRevenues(data.monthly)
                    break;
                case "yearly_monthly":
                    setRevenues(data.yearly_monthly)
                    break;
                case "yearly_wint_sum":
                    setRevenues(data.yearly_wint_sum)
                    break;
            }
        }
    }
    const tbody = revenues ? (revenues.map((item, index) => (
        <tr key={index}>
            {(group === 'yearly' || group === 'yearly_monthly' || group === 'yearly_wint_sum') && <td>{item.year}</td>}
            {(group === 'monthly' || group === 'yearly_monthly') && <td>{item.month}</td>}
            {(group === 'wint_sum' || group === 'yearly_wint_sum') && <td>{wint_sum[item.wint_sum]}</td>}
            <td>{formatCurrency(item.optimization_revenue, "USD", 0)}</td>
            <td>{formatCurrency(item.optimization_revenue_per_kwmonth)}</td>
            <td>{formatCurrency(item.top_bottom_revenue, "USD", 0)}</td>
            <td>{formatCurrency(item.top_bottom_revenue_per_kwmonth)}</td>
            <td>{item.cycle_count.toFixed(1)}</td>
        </tr>
    ))) : null;

    useEffect(() => {
        if (perfectForesightId) {
            onCompleteFetchForesightData(perfectForesightSumData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perfectForesightId])

    return (
        <Loading isLoading={isLoading} errorMessage={errorMessage}>
            <Table striped hover responsive>
                <thead>
                    <tr>
                        {(group === 'yearly' || group === 'yearly_monthly' || group === 'yearly_wint_sum') && <th>Year</th>}
                        {(group === 'monthly' || group === 'yearly_monthly') && <th>Month</th>}
                        {(group === 'wint_sum' || group === 'yearly_wint_sum') && <th>Season</th>}
                        <th>PF Revenue</th>
                        <th>PF Revenue/kWm</th>
                        <th>TB Revenue</th>
                        <th>TB Revenue/kWm</th>
                        <th>Cycle Count</th>
                    </tr>
                </thead>
                <tbody>
                    {tbody}
                </tbody>
            </Table>
        </Loading>
    )
}
import { ApplicationState } from "store";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import sprites from "common/assets/img/sprites.svg";
import { styleFlex, styleFlexColumn } from 'common/components/style';
import classes from "common/components/divContainer/style.module.scss";
import { setDateRange } from "store/slices/settings-slice";
import { MessageErrorDateRange } from "./messageErrorDateRage";
import { convertDateToString } from "common/utils/utils";

interface Props {
    isLoadingEnabled: boolean,
    errorDateRange: any,
    errorDateRangeSim: any,
    isEntitySelected: boolean

}
export const DateRange = ({ isLoadingEnabled, errorDateRange, errorDateRangeSim, isEntitySelected }: Props) => {
    /**Constants */
    const dispatch = useDispatch();

    /**States */
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [minDate, setMinDate] = useState<any>(null);
    const [maxDate, setMaxDate] = useState<any>(null);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    /**Selectors */
    const priceIdSelected = useSelector((store: ApplicationState) => store.settingsReducer.priceId);
    const priceMinTimeStampSelected = useSelector((store: ApplicationState) => store.settingsReducer.priceMintimestamp);
    const priceMaxTimeStampSelected = useSelector((store: ApplicationState) => store.settingsReducer.priceMaxtimestamp);
    const dateRange = useSelector((store: ApplicationState) => store.settingsReducer.dateRange);
    const priceTimezoneSelected = useSelector((store: ApplicationState) => store.settingsReducer.priceTimezone);
    const forecastId = useSelector((store: ApplicationState) => store.settingsReducer.forecastId);

    const updateStartDate = (date: any) => {
        setStartDate(date);
        dispatch(setDateRange([convertDateToString(date), convertDateToString(endDate)]));
    }

    const updateEndDate = (date: any) => {
        setEndDate(date);
        dispatch(setDateRange([convertDateToString(startDate), convertDateToString(date)]));

    }

    useEffect(() => {
        // date range is at forecast level or nrg priceId level
        if (!isEntitySelected && !forecastId) {
            setStartDate(null);
            setEndDate(null);
            setIsDisabled(true); 
        } else {
            if (priceMinTimeStampSelected && priceTimezoneSelected) {
                const minTime = dateRange ? dateRange[0] : priceMinTimeStampSelected;
                setMinDate(new Date(priceMinTimeStampSelected));
                setStartDate(new Date(minTime));
            }

            if (priceMaxTimeStampSelected && priceTimezoneSelected) {
                const maxTime = dateRange ? dateRange[1] : priceMaxTimeStampSelected;
                setMaxDate(new Date(priceMaxTimeStampSelected));
                setEndDate(new Date(maxTime));
            }
            setIsDisabled(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceIdSelected, forecastId, isEntitySelected]);

    const handleDateRangeErr = (errValue: boolean) => {
        errorDateRange(errValue);
    }
    const handleErrDateRangeSim = (errValue: boolean) => {
        // bad date range, disable simulate button
        errorDateRangeSim(errValue);
    }

    return (
        <div className={classes.component} style={styleFlexColumn}>
            <div className={classes.component100} style={styleFlex}>
                <div className={classes.component50}>
                    <label>Start</label>
                    <DatePicker className={classes.datepicker}
                        minDate={minDate}
                        maxDate={maxDate}
                        dateFormat="MM/dd/yyyy"
                        selected={startDate}
                        disabled={isDisabled || isLoadingEnabled}
                        onChange={date => date && updateStartDate(date)}
                        placeholderText="Start date"
                    />
                    <span>
                        <svg>
                            <use xlinkHref={`${sprites}#icon-calendar`}></use>
                        </svg>
                    </span>
                </div>
                <div className={classes.component50}>
                    <label>End</label>
                    <DatePicker className={classes.datepicker}
                        minDate={minDate}
                        maxDate={maxDate}
                        dateFormat="MM/dd/yyyy"
                        selected={endDate}
                        disabled={isDisabled || isLoadingEnabled}
                        onChange={date => date && updateEndDate(date)}
                        placeholderText="End date"
                    />
                    <span>
                        <svg>
                            <use xlinkHref={`${sprites}#icon-calendar`}></use>
                        </svg>
                    </span>
                </div>
            </div>
            <MessageErrorDateRange dateRangeErr={handleDateRangeErr} dateRangeErrSim={handleErrDateRangeSim} />
        </div>
    )
}

import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import sprites from "common/assets/img/sprites.svg";
import classes from "./../../style.module.scss";
import { createMetadataSimulate, DataToFileDownload, formatCurrency} from "common/utils/utils";
import { ForesightRevenueData, SumForesightData } from "common/interfaces/perfectForesightData";
import { wint_sum } from "common/components/lineChart/values";
import { SourceTypes, useNodesInSource } from "store/types/source-types";

export const ButtonDownloadRevenueTablesCsv = () => {
    /**SELECTORS */
    const perfectForesightSumData = useSelector((store: ApplicationState) => store.settingsReducer.perfectForesightSumData);
    const perfectForesightId = useSelector((store: ApplicationState) => store.chartReducer.perfectForesightId);
    const settingsReducer = useSelector((store: ApplicationState) => store.settingsReducer);

    /**CONSTANTS */
    let titleButton = 'Download Revenue Table';
    const useNodes = useNodesInSource(settingsReducer.sourceId);


    const downloadPerForRevenueCSV = (data: SumForesightData | null) => {
        // Convert data array to CSV
        if (data) {
            let csvRows: Array<string> = [];
            // total
            csvRows = convertPerForToRows([data.total], "total")
            csvRows = [...csvRows, ...convertPerForToRows(data.monthly, "monthly")]
            csvRows = [...csvRows, ...convertPerForToRows(data.wint_sum, "wint_sum")]
            csvRows = [...csvRows, ...convertPerForToRows(data.yearly, "yearly")]
            csvRows = [...csvRows, ...convertPerForToRows(data.yearly_wint_sum, "yearly_wint_sum")]
            csvRows = [...csvRows, ...convertPerForToRows(data.yearly_monthly, "yearly_monthly")]
            let  csvContent = csvRows.join('\n');
            csvContent = createMetadataSimulate(settingsReducer) + "\n" + csvContent
            let filename = "RevenueTable";
            const price = settingsReducer.priceList[0];
            if (price.sourceId === SourceTypes.NRG_STREAM) {
                filename = "RevenueTable_" + price.streamName;
            } else {
                if (useNodes) {
                    filename = "RevenueTable_" + price.forecastName + "_" + price.nodeName;
                } else {
                    filename = "RevenueTable_" + price.forecastName + "_" + price.locationName;
                }
            }
            DataToFileDownload(csvContent, filename);
        }
      };

    const convertPerForToRows = (data: Array<ForesightRevenueData>, group: string) => {
        const rows: Array<string> = [];
        switch (group) {
            case "total":
                rows.push("Total");
                rows.push(...processPerForRows(data, false, false, false));
                rows.push("");
                break;
            case "yearly":
                rows.push("Yearly");
                rows.push(...processPerForRows(data, true, false, false));
                rows.push("");
                break;
            case "wint_sum":
                rows.push("Seasonally");
                rows.push(...processPerForRows(data, false, false, true));
                rows.push("");
                break;
            case "monthly":
                rows.push("Monthly");
                rows.push(...processPerForRows(data, false, true, false));
                rows.push("");
                break;
            case "yearly_monthly":
                rows.push("Month by Year");
                rows.push(...processPerForRows(data, true, true, false));
                rows.push("");
                break;
            case "yearly_wint_sum":
                rows.push("Season By Year");
                rows.push(...processPerForRows(data, true, false, true));
                rows.push("");
                break;
        }
        return rows;
    }

    const processPerForRows = (data: Array<ForesightRevenueData>, year: boolean, month: boolean, season: boolean) => {
        const rows: Array<string> = [];
        let header: Array<string> = [];
        if (year) {
            header.push("Year");
        }
        if (month) {
            header.push("Month");
        }
        if (season) {
            header.push("Season");
        }
        header = [...header, "PF Revenue,PF Revenue/kWm,TB Revenue,TB Revenue/kWm,Cycle Count"];
        rows.push(header.join(","));
        for(const x of data) {
            let bodyRow = [];
            if (year) {
                bodyRow.push(`${x.year}`)
            }
            if (month) {
                bodyRow.push(`${x.month}`)
            }
            if (season) {
                bodyRow.push(`${wint_sum[x.wint_sum]}`)
            }
            bodyRow.push(`"${formatCurrency(x.optimization_revenue, "USD", 0)}"`);
            bodyRow.push(`"${formatCurrency(x.optimization_revenue_per_kwmonth)}"`);
            bodyRow.push(`"${formatCurrency(x.top_bottom_revenue, "USD", 0)}"`);
            bodyRow.push(`"${formatCurrency(x.top_bottom_revenue_per_kwmonth)}"`);
            bodyRow.push(`"${x.cycle_count.toFixed(1)}"`);
            rows.push(bodyRow.join(","))
        }
        return rows;
    }

    const handleDownload = () => {
        // TODO check for fixed or perfect
        if (perfectForesightId) {
            downloadPerForRevenueCSV(perfectForesightSumData);
        } else {
          alert('No data available to download');
        }
      };

    return (
        <div className={classes.card__dropdownitem}>
            <button onClick={handleDownload}>
                <span>
                    <svg>
                        <use xlinkHref={`${sprites}#icon-export`}></use>
                    </svg>
                </span>
                {titleButton}
            </button>
        </div>
    )
}

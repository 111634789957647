import { useState } from "react";
import { Accordion } from "react-bootstrap";
import { ToggleState } from "common/interfaces/toggleState";
import { BulkNodesDisplay } from "./bulkNodesDisplay";
import { BulkNodeResult } from "./bulkNodeResult";

interface Props {
    checkAypaToken: () => void;
}
export const BulkMainSection = ({checkAypaToken}: Props) => {

    const [toggleState] = useState<ToggleState>({ openCardId: '0', cardIdToOpen: '0' });

    return (
        <Accordion defaultActiveKey="0">
            <BulkNodesDisplay eventKey="0" stack={toggleState} checkAypaToken={checkAypaToken}/>
            <BulkNodeResult eventKey="1" stack={toggleState} checkAypaToken={checkAypaToken}/>
        </Accordion>
    )
}

import  classes  from "../style.module.scss";
import { DivContainer, DivContainer50 } from "common/components/divContainer";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import AYPAInput from "common/components/input";
import { setTbSettings } from "store/slices/bulk-slice";
import { DateRange } from "common/components/dateRange";
import DefaultButton from "common/components/button";
import { ApplicationState } from "store";
import { convertDateToString } from "common/utils/utils";



export const BulkTbSettings = () => {
    /**Constants */
    const dispatch = useDispatch();
    const errorRteMessage = "Value must be between 0.1 and 100";
    const errorBatteryPowerMessage = "Value must be greater than 0";
    const initRte = 88.0;
    const initBatteryPower = 100;
    const initBatteryHour = 4;
    const hoursList = [1,2,3,4,5,6,7,8,9,10,11,12].map((s: number) => {
            return { value: s, label: s.toString() + " hr" };
        });
    const currentYear = new Date().getFullYear();
    let initStartDate = new Date(currentYear, 0, 1);
    let initEndDate = new Date(currentYear, 11, 31);

    const bulkPriceList = useSelector((store: ApplicationState) => store.bulkReducer.bulkPriceList);
    
    const [rte, setRte] = useState<number | null>(initRte);
    const [batteryPower, setBatteryPower] = useState<number | null>(initBatteryPower);
    const [batteryHour, setBatteryHour] = useState<number | null>(initBatteryHour);
    const [hasRteError, setHasRteError] = useState(false);
    const [hasBatteryPowerErr, setHasBatteryPowerErr] = useState(false);
    const [hasSubmitErr, setHasSubmitErr] = useState(false);
    const [submitErrMessage, setSubmitErrMessage] = useState("");
    const [selectedOption, setSelectedOption] = useState<Array<any>>([{value: initBatteryHour, label: initBatteryHour.toString() + " hr"}]);
    const [startDate, setStartDate] = useState<Date | null>(initStartDate);
    const [endDate, setEndDate] = useState<Date | null>(initEndDate);
    const [dateRange, setDateRange] = useState<Array<string> | null>([convertDateToString(initStartDate), convertDateToString(initEndDate)]);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);


    const handleDataFromDateRange = (startDate: Date | null, endDate: Date | null ) => {
        setDateRange([convertDateToString(startDate), convertDateToString(endDate)]);
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const handleOnDateError = (dateError: boolean) => {
        setSubmitDisabled(dateError);
    }

    const handlerOnChangeRte = (data: any) => {
        if (data === '') {
            setSubmitDisabled(true);
            setRte(null);
        } else {
            const num_data = Number(data);
            if (0 >= num_data || num_data > 100.0) {
                setHasRteError(true);
                setRte(null);
                setSubmitDisabled(true);
            } else {
                setHasRteError(false)
                setRte(Number(num_data));
                setSubmitDisabled(false);
            }
        }
    }
    const handlerOnChangeRatedPower = (data: any) => {
        const num_data = Number(data);
        if (data === '' || num_data <= 0) {
            setSubmitDisabled(true);
            setBatteryPower(null);
            setHasBatteryPowerErr(true);
        } else {
            setSubmitDisabled(false);
            setBatteryPower(Number(data));
            setHasBatteryPowerErr(false);
        }
        
    }
    const handlerOnChangeHour = (data: any) => {
        setBatteryHour(data.value);
        setSelectedOption(data)
    }
 
    const onSearch = () => {
        // common - start, end, power, duration, rte
        // nrg - stream id, granularity
        // forecast - forecast loc, node name
        const x = { dateRange: dateRange,
            rte: rte,
            batteryPower: batteryPower,
            batteryHour: batteryHour
        };
        console.log('TB props', x)
        
        dispatch(setTbSettings({ dateRange: dateRange,
            batteryHour: batteryHour,
            batteryPower: batteryPower,
            roundTripEfficiency: rte
        }));
    };

    useEffect(() => {
        const sizeOfReq = bulkPriceList.length;
        if (sizeOfReq === 0) {
            setSubmitErrMessage("");
            setSubmitDisabled(true);
            setHasSubmitErr(false);
        } else if (sizeOfReq > 100) {
            setSubmitErrMessage("100 max Nodes");
            setSubmitDisabled(true);
            setHasSubmitErr(true);
        } else {
            setSubmitDisabled(false);
            setHasSubmitErr(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bulkPriceList]);

    const onKeyPressHandler = () => { }

    return (
        <>
        <DateRange onSetDateRange={handleDataFromDateRange} onDateError={handleOnDateError} initStartDate={startDate} initEndDate={endDate}/>
        <DivContainer>
        <>
            <DivContainer50>
                <AYPAInput
                    config={{ type: 'number', id: 'ratedPower', placeholder: '100', min: '0', max: '1000', step: '10' }}
                    label="Rated Power"
                    unit= "MW"
                    disabled={false}
                    onChangeCallback={handlerOnChangeRatedPower}
                    onKeyPressCallback={onKeyPressHandler}
                    hasError={hasBatteryPowerErr}
                    errorMessage={errorBatteryPowerMessage}
                    initalValue={initBatteryPower}
                />
            </DivContainer50>
            <DivContainer50 label="Duration">
                <Select
                    id="batteryHours"
                    onChange={handlerOnChangeHour}
                    options={hoursList}
                    value={selectedOption}
                    className='react-select-container-2ndCol'
                    classNamePrefix="react-select"
                    menuPlacement='auto'
                    isDisabled={false}
                />
            </DivContainer50>
        </>
        </DivContainer>
        <DivContainer>
        <>
            <DivContainer50>
                <AYPAInput
                    config={{ type: 'number', id: 'rteInput', placeholder: '88', min: '0', max: '100', step: '0.1' }}
                    label="Round Trip Efficiency"
                    unit= "%"
                    disabled={false}
                    onChangeCallback={handlerOnChangeRte}
                    onKeyPressCallback={onKeyPressHandler}
                    hasError={hasRteError}
                    errorMessage={errorRteMessage}
                    initalValue={initRte}
                />
            </DivContainer50>
        </>
        </DivContainer>
        <DivContainer>
            <div className={classes.actionButton}>
                <>
                <DefaultButton primary="primary" title="Top Bottom Submit" disabled={submitDisabled} onClick={() => onSearch()} ></DefaultButton>
                { hasSubmitErr && <span className="error">{submitErrMessage}</span>}
                </>
            </div>
        </DivContainer>
        </>

    )
}

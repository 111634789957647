import { RatedPower } from "../../../../common/aypaForms/ratedPower";
import { styleFlex, styleFlexColumn } from 'common/components/style';
import { DivContainer, DivContainer100 } from "common/components/divContainer";
import { BatteryHours } from "../../../../common/aypaForms/batteryHours";

interface Props{
    isLoadingEnabled: boolean,
    isEntitySelected: boolean
}
export const Battery = ({ isLoadingEnabled, isEntitySelected}: Props) => {
    /**Contanst */
    const labelTitle = "Battery";

    return (
        <DivContainer style={styleFlexColumn}>
            <>
                <DivContainer100><label><b>{labelTitle}</b></label></DivContainer100>

                <DivContainer100 style={styleFlex} >
                    <>
                        <RatedPower isLoadingEnabled={isLoadingEnabled} isEntitySelected={isEntitySelected} />
                        <BatteryHours isLoadingEnabled={isLoadingEnabled}  isEntitySelected={isEntitySelected} />
                    </>
                </DivContainer100>
            </>
        </DivContainer>
    )
}

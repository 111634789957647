import { ApplicationState } from "store";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSimulateButton } from "store/slices/settings-slice";
import { resetSimulation } from "store/slices/chart-slice";
import { SourceTypes } from "store/types/source-types";

interface Props {
    disableSim: boolean,
    isEntitySelected: boolean
}

const SimulationButton = ({disableSim, isEntitySelected}: Props) => {

    /*Constants */
    const dispatch = useDispatch();
    const titleButton = "Simulate";

    /**States */
    const [isDisabled, setIsDisabled] = useState<boolean>(true);   
    
    /**Selectors */
    const settings = useSelector((store: ApplicationState) => store.settingsReducer);
    const isSimulateLoading = useSelector((store: ApplicationState) => store.chartReducer.isLoading);
    const isPriceLookupDone = useSelector((store: ApplicationState) => store.pricesReducer.isDone);

    const handlerOnClick = () => {
        if (!settings.simulateButtonclicked) {
            dispatch(resetSimulation())
            setTimeout(() => {
                dispatch(setSimulateButton(true));
            }, 0);
        }
    }
    useEffect(() => {
        if (isEntitySelected && settings.priceList.length > 0) {
            const price = settings.priceList[0];
            if ((price.sourceId === SourceTypes.NRG_STREAM && isPriceLookupDone) || price.sourceId !== SourceTypes.NRG_STREAM ) {
                if (settings.batteryPower && settings.fixedSchedule && isPriceLookupDone) {
                    setIsDisabled(false);
                } else if (settings.batteryPower && settings.perfectForesight && settings.cycleCount && settings.roundTripEfficiency) {
                    setIsDisabled(false);
                } else {
                    setIsDisabled(true);
                }
            } else {
                setIsDisabled(true);
            }
        } else {
            setIsDisabled(true);
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isEntitySelected,
        settings.batteryPower,
        settings.fixedSchedule,
        settings.perfectForesight,
        settings.cycleCount,
        settings.sourceId,
        settings.roundTripEfficiency,
        isPriceLookupDone
    ]);

    return (
        <button onClick={handlerOnClick} disabled={isDisabled || isSimulateLoading || disableSim}>{titleButton}</button>
    )
}

export default SimulationButton;

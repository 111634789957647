import { useEffect, useState } from "react";
import { ApplicationState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { setGetPriceButton } from "store/slices/settings-slice";
import { Loading } from "common/components/loading";
import { PriceProfileTabs } from "./priceProfileTabs";
import { useExecutePriceLookup } from "../hooks/useExecutePriceLookup";
import { useInitRunPriceLookup } from "../hooks/useInitRunPriceLookup";
// import { setPriceFail } from "pages/home/stores/actions/price-action";
import { requestFailed } from "store/slices/prices-slice";
import { fetchRunPriceLookup } from "store/slices/prices-slice";
import { SourceTypes } from "store/types/source-types";
import { wsDisconnect } from "store/slices/websocket-slice";
import config from 'config';

interface Props {
    checkAypaToken: () => void;
}

export const PriceWrapper = ({checkAypaToken}: Props) => {
    /**Contants */
    const dispatch = useDispatch();
    const showCancelButton = true;
    const timeoutTime = 180000; //3min update error message to match

    /**Selectors */
    const isLoadingData = useSelector((store: ApplicationState) => store.pricesReducer.isLoadingData)
    const errorMessage = useSelector((store: ApplicationState) => store.pricesReducer.errorMessage);
    const getPriceButtonclicked = useSelector((store: ApplicationState) => store.settingsReducer.getPriceButtonclicked);
    const executeRunPriceLookup = useSelector((store: ApplicationState) => store.pricesReducer.executeRunPriceLookup);
    const sourceId = useSelector((store: ApplicationState) => store.settingsReducer.sourceId);

    const [timeOutId, setTimeOutId] = useState<any>(null); 

    /** RunPriceLookup */
    useInitRunPriceLookup(checkAypaToken);

    useExecutePriceLookup();

    useEffect(() => {
        if (getPriceButtonclicked) {
            dispatch(fetchRunPriceLookup());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPriceButtonclicked]);

    useEffect(() => {
        if (executeRunPriceLookup) {
            if (timeOutId) {
                clearTimeout(timeOutId)
                setTimeOutId(null);
            }

            const timeOut = setTimeout(() => {
                dispatch(requestFailed({"message": "Request timed out.", "details": "Request took longer than 3mins."}));
                dispatch(setGetPriceButton(false));
            }, timeoutTime);
            setTimeOutId(timeOut)
        } else {
            setTimeOutId(null);
            clearTimeout(timeOutId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeRunPriceLookup]);

    function cancelRequest() {
        if (sourceId === SourceTypes.NRG_STREAM) {
            dispatch(wsDisconnect(config.priceWs))
        }
        setTimeout(() => {
            dispatch(requestFailed(null));
            dispatch(setGetPriceButton(false));
        }, 0);
    }


    const loadingMessage =
        <p>
            <b>Fetching Data</b><br />
          This may take up to 3 minute...
        </p>

    return (
        <Loading isLoading={isLoadingData} errorMessage={errorMessage} showButtonCancel={showCancelButton} loadingMessage={loadingMessage} callback={() => cancelRequest()}>
            <PriceProfileTabs isLoading={isLoadingData} />
        </Loading>
    );
}

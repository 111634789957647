import { DateTime } from 'luxon';
import { SettingState } from 'store/states/settings-state';
import { PriceListData } from 'common/interfaces/priceListData';
import { PriceData, PriceDataAverage } from 'common/interfaces/priceData';


export const getLocalizedDate = (date: string, tzString: string, excl: boolean): string => {
  let newDate = date ? DateTime.fromISO(date) : DateTime.local();
  if (tzString !== "") {
    newDate = newDate.setZone(tzString)
  }
  if (excl) newDate = newDate.plus({ days: 1 });
  return newDate.year + '-' + newDate.month.toString().padStart(2, "0") + '-' + newDate.day.toString().padStart(2, "0") + 'T00:00:00';
}

export const convertDateToString = (date: Date | null) => {
    if (date) {
        return date.toISOString().replace(/\.000Z$/, '');
    } else {
        return "";
    }
}

/**
 * takes in blobed csv data and downloads to user
 * @param data - blobed csv data.
 * @param ReportTitle - filename to be saved.
 * @returns  nothing
 */
export const DataToFileDownload = (data: any, ReportTitle: any) => {
    const blobData = new Blob([data], { type: 'application/octet-stream' })
    const blobUrl = URL.createObjectURL(blobData);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = ReportTitle.replace(/ /g, "_") + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  };

export const downloadChartImage = (chart: any, filename: string) => {

  var uri = chart;
  var link = document.createElement("a");
  link.href = uri;

  link.download = filename + ".png";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const formatDate = (date: string | null) => {
  return date ? date.split('T')[0] : date;
}


export const timezoneMap: { [key: string]: { prevailing: string; standard: string } } = {
  'US/Pacific': {
    prevailing: 'PPT',
    standard: 'PST',
  },
  'US/Eastern': {
    prevailing: 'EPT',
    standard: 'EST',
  },
  'US/Central': {
    prevailing: 'CPT',
    standard: 'CST',
  },
  'EST': {
    prevailing: 'EPT',
    standard: 'EST',
  },
  'Etc/GMT+8': {
    prevailing: 'PPT',
    standard: 'PST',
  },
  'Etc/GMT+5': {
    prevailing: 'EPT',
    standard: 'EST',
  },
  'Etc/GMT+6': {
    prevailing: 'CPT',
    standard: 'CST',
  },
  default:{
    prevailing: "",
    standard: ""
  }
};

export function addDayToDateStr(dateStr: string | null) {
    let dateExcStr = null;
    if (dateStr) {
        const dateDate = DateTime.fromISO(dateStr)
        const dateDateExc = dateDate.plus({ days: 1 });
        dateExcStr = dateDateExc.year + '-' + dateDateExc.month.toString().padStart(2, "0") + '-' + dateDateExc.day.toString().padStart(2, "0") + 'T00:00:00'
    }
    return dateExcStr;
}

export function createMetadataPrice(reducerSettings: SettingState, onOffPeak: boolean, isSimulation: boolean) {

    const attributes: (keyof PriceListData)[] = ['sourceName', 'regionName', 'isoName', 'forecastName', 'inflationVector', 'locationName','nodeName', 'streamName'];
    const attributesNames = ['Source', 'Region', 'Region', 'Forecast', 'Inflation Vector', 'Location', 'Node', 'Price'];
    const matrix: (string | null | number | Array<string>)[][] = [];
    const isHourly = reducerSettings.priceList[0].granularity !== "FIVEMIN";

    // Populate the matrix with values
    for (const attr of attributes) {
        const values: (string | null | number | Array<string>)[] = [];
        for (const obj of reducerSettings.priceList) {
            values.push(obj[attr]);
            if (onOffPeak) {
                // onoff peak has 2 cols of data for each metadata - skips a col
                values.push("");
            }
        }
        matrix.push(values);
    }
    let offset = ",";
    if (!isSimulation) {
        offset = getAttributeOffset(reducerSettings.priceTab, isHourly)
    }
    let result = "";
    for (let i = 0; i < matrix.length; i++) {
        if (i === 1) {
            // region name or iso name will always be there
            result += attributesNames[i] + offset;
            const regions = []
            for (let j = 0; j < matrix[i].length; j++) {
                if (matrix[i][j]) {
                    regions.push(matrix[i][j]);
                } else {
                    regions.push(matrix[i+1][j]);
                }
            }
            result += regions.join(",");
        } else if (i === 2) {
            continue;
        } else {
            result += attributesNames[i] + offset;
            result += matrix[i].map(val => val ?? "").join(",");
        }

        result += "\n";
    }
    return result;
}

export function createMetadataSimulate(reducerSettings: SettingState) {
    let metadata = createMetadataPrice(reducerSettings, false, true);
    if (reducerSettings.fixedSchedule) {
        metadata += `Simulation Type,Fixed Schedule\n`;
    }
    if (reducerSettings.perfectForesight) {
        metadata += `Simulation Type,Perfect Foresight\n`;
    }
    if (reducerSettings.batteryPower) {
        metadata += `Rated Power,${reducerSettings.batteryPower}\n`;
    }
    if (reducerSettings.batteryHour) {
        metadata += `Duration,${reducerSettings.batteryHour} hr\n`;
    }
    if (reducerSettings.roundTripEfficiency) {
        metadata += `Round Trip Efficiency,${reducerSettings.roundTripEfficiency/100}\n`;
    }
    if (reducerSettings.cycleCount && reducerSettings.perfectForesight) {
        metadata += `Cycles Per Day,${reducerSettings.cycleCount}\n`;
    }
    // skipping PV 8760
    return metadata;
}

function getAttributeOffset(priceTab: string, isHourly: boolean) {
    // depending on the file, there are more cols before the data
    // "hour" or "hour","year", or "hour","year","month", or "hour","min"
    let n = 1
    switch (priceTab){
        case "overall":
            n = 1;
            break;
        case "monthly":
            n = 2;
            break;
        case "wint_sum":
            n = 2;
            break;
        case "yearly":
            n = 2;
            break;
        case "wint_sum_by_year":
            n = 3;
            break;
        case "month_by_year":
            n = 3;
            break;
        case "raw":
            n = 1;
            break;
        default:
            n = 1;
            break;
    }
    if (!isHourly) {
        n += 1;
    }
    return ",".repeat(n);
} 

export function formatCurrency(amount: number, currency="USD", maxDecimalPlaces=2) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: maxDecimalPlaces
    }).format(amount);
    
}

export function generateRandomNumberAsString(length: number) {
    let randomNumber = '';
    for (let i = 0; i < length; i++) {
        const digit = Math.floor(Math.random() * 10);
        randomNumber += digit;
      }
      return randomNumber;
  }


export function averagePriceData(data1: Array<PriceData>, group_name: string, label1: string, data2?: Array<PriceData>, label2?: string): Array<PriceDataAverage>{
    // data: one long array
    let avgData: Array<PriceDataAverage> = [];
    if (data1.length === 0 || (data2 && data2.length === 0)) {
        return avgData;
    }
    switch (group_name){
        case "hour":
        case "five_min":
            // overall - average all
            const total1 = data1.reduce((acc, item) => acc + item.mean_value, 0);
            avgData.push({"name": label1, "price": total1/data1.length});
            if (data2 && label2){
                const total2 = data2.reduce((acc, item) => acc + item.mean_value, 0);
                avgData.push({"name": label2, "price": total2/data2.length});
            }
            break;
        case "hour_month":
        case "five_min_month":
            const monthly1: any = averagePriceDataCalc(data1, "month");
            avgData = avgData.concat(Object.keys(monthly1).map((month) => ({
                name: label1,
                price: monthly1[month].totalValue / monthly1[month].count,
                month: parseInt(month)
            })));
            if (data2 && label2) {
                const monthly2: any = averagePriceDataCalc(data2, "month")
                avgData = avgData.concat(Object.keys(monthly2).map((month) => ({
                    name: label2,
                    price: monthly2[month].totalValue / monthly2[month].count,
                    month: parseInt(month)
                })));
            }
            break;
        case "hour_year":
        case "five_min_year":
            const yearly1: any = averagePriceDataCalc(data1, "year");
            avgData = avgData.concat(Object.keys(yearly1).map((year) => ({
                name: label1,
                price: yearly1[year].totalValue / yearly1[year].count,
                year: parseInt(year)
            })));
            if (data2 && label2) {
                const yearly2: any = averagePriceDataCalc(data2, "year")
                avgData = avgData.concat(Object.keys(yearly2).map((year) => ({
                    name: label2,
                    price: yearly2[year].totalValue / yearly2[year].count,
                    year: parseInt(year)
                })));
            }
            break;
        case "hour_wint_sum":
        case "five_min_wint_sum":
            const wint_sum1: any = averagePriceDataCalc(data1, "wint_sum");
            avgData = avgData.concat(Object.keys(wint_sum1).map((wint_sum11) => ({
                name: label1,
                price: wint_sum1[wint_sum11].totalValue / wint_sum1[wint_sum11].count,
                wint_summ: parseInt(wint_sum11)
            })));
            if (data2 && label2) {
                const wint_sum2: any = averagePriceDataCalc(data2, "wint_sum");
                avgData = avgData.concat(Object.keys(wint_sum2).map((wint_sum22) => ({
                    name: label2,
                    price: wint_sum2[wint_sum22].totalValue / wint_sum2[wint_sum22].count,
                    wint_summ: parseInt(wint_sum22)
                })));
            }
            break;
        case "hour_year_month":
        case "five_min_year_month":
            const mon_year1: any = averagePriceDataCalc(data1, "month", "year");
            avgData = avgData.concat(Object.keys(mon_year1).map((mon_year11) => {
                const [month11, year11] = mon_year11.split("_");
                return {
                    name: label1,
                    price: mon_year1[mon_year11].totalValue / mon_year1[mon_year11].count,
                    month: parseInt(month11),
                    year: parseInt(year11)
                }
            }));
            if (data2 && label2) {
                const mon_year2: any = averagePriceDataCalc(data2,  "month", "year");
                avgData = avgData.concat(Object.keys(mon_year2).map((mon_year22) => {
                    const [month22, year22] = mon_year22.split("_");
                    return {
                        name: label2,
                        price: mon_year2[mon_year22].totalValue / mon_year2[mon_year22].count,
                        month: parseInt(month22),
                        year: parseInt(year22)
                    }
                }));
            }
            break;
        case "hour_year_wint_sum":
        case "five_min_year_wint_sum":
            const year_wint_sum1: any = averagePriceDataCalc(data1, "wint_sum", "year");
            avgData = avgData.concat(Object.keys(year_wint_sum1).map((year_wint_sum11) => {
                const [wint_sum11, year11] = year_wint_sum11.split("_");
                return {
                    name: label1,
                    price: year_wint_sum1[year_wint_sum11].totalValue / year_wint_sum1[year_wint_sum11].count,
                    year: parseInt(year11),
                    wint_summ: parseInt(wint_sum11)
                }
            }));
            if (data2 && label2) {
                const year_wint_sum2: any = averagePriceDataCalc(data2,  "wint_sum", "year")
                avgData = avgData.concat(Object.keys(year_wint_sum2).map((year_wint_sum22) => {
                    const [wint_sum22, year22] = year_wint_sum22.split("_");
                    return {
                        name: label2,
                        price: year_wint_sum2[year_wint_sum22].totalValue / year_wint_sum2[year_wint_sum22].count,
                        year: parseInt(year22),
                        wint_summ: parseInt(wint_sum22)
                    }
                }));
            }
            break;
        default:
            break;
    }
    return avgData
}

function averagePriceDataCalc(data: Array<PriceData>, attr1: string, attr2?: string) {
    const dataset: any = {}
    if (attr2) {
        data.forEach(item => {
            const key1 = item[attr1 as keyof PriceData] as number;
            const key2 = item[attr2 as keyof PriceData] as number;
            if (key1 !== undefined && key1 !== null && key2 !== undefined && key2 !== null) {
                if (!dataset[key1.toString()+"_"+key2.toString()]) {
                    dataset[key1.toString()+"_"+key2.toString()] = { totalValue: 0, count: 0 };
                }
                dataset[key1.toString()+"_"+key2.toString()].totalValue += item.mean_value;
                dataset[key1.toString()+"_"+key2.toString()].count += 1;
            }
        });
    } else {
        data.forEach(item => {
            const key = item[attr1 as keyof PriceData] as number;
            if (key !== undefined && key !== null) {
                if (!dataset[key]) {
                    dataset[key] = { totalValue: 0, count: 0 };
                }
                dataset[key].totalValue += item.mean_value;
                dataset[key].count += 1;
            }
        });
    }

    return dataset;

}
import  classes from "../style.module.scss";
import { DivContainer, DivContainer50 } from "common/components/divContainer";
import styles from 'common/components/divContainer/style.module.scss';
import { useEffect, useState } from 'react';
import { getAllIso, getAllLocationByFilter, getAllSubstationsByFilter } from 'common/adapters/entities';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import toast from 'react-hot-toast';
import Select from 'react-select';
import AYPAInput from "common/components/input";
import DefaultButton from 'common/components/button';
import { Form } from "react-bootstrap";
import { setSearchEntites } from "store/slices/bulk-slice";

interface IsoObject {
    value: number;
    label: string;
    timezone: string;
}
interface SelectObject {
    value: number;
    label: string;
}

export const Historical = () => {
    /**Constants */
    const dispatch = useDispatch();
    const nodeNameErrorMessage = "Error Node Name";
    // always getting both DA and RT for prices
    // const listPriceTypes: Array<any> = [
    //     { value: 'DAY-AHEAD', label: 'Day Ahead' },
    //     { value: 'REAL-TIME', label: 'Real Time' }
    // ]
    const sourceId = useSelector((store: ApplicationState) => store.settingsReducer.sourceId);

    const [isListLoading, setListLoading] = useState<boolean>(true);
    const [isNodeActive, setNodeActive] = useState(true);
    const [listIso, setListIso] = useState<Array<IsoObject>>([]);
    const [listLocation, setListLocation] = useState<Array<SelectObject>>([]);
    const [listSubstation, setListSubstation] = useState<Array<SelectObject>>([]);
    const [isoSelected, setIsoSelected] = useState<IsoObject | null>(null);
    const [locationSelected, setLocationSelected] = useState<SelectObject | null>(null);
    const [substationSelected, setSubstationSelected] = useState<SelectObject | null>(null);
    // const [priceTypeSelected, setPriceTypeSelected] = useState<SelectObject | null>(null);
    const [nodeNameValue, setNodeNameValue] = useState('');

    const loadIsoList = async () => {
        setListLoading(true);
        try {
            const response = await getAllIso(sourceId);
            const options = response.data.items.map((s: any) => {
                return { value: s.id, label: s.name, timezone: s.timezone }
            })
            if (options.length > 0) {
                setIsoSelected(options[0]);
                loadLocationList(options[0].value, null);
                loadSubsationList(options[0].value, null);
                // set location
              }
            setListIso(options)
        } catch {
            setListIso([])
            toast.error("Failed to load the ISO list. Please try again.");
        } finally {
            setListLoading(false)
        }
    }
    const loadLocationList = async (isoId: number | null, substationId: number | null) => {
        setListLoading(true);
        try {
            if (isoId === null) {
                setListLocation([]);
                return;
            }
            const response = await getAllLocationByFilter(sourceId, isoId, null, substationId);

            // Check if the currentRegionId and currentForecastId are still the latest values
            // TODO Do we need this check?
            // if (currentIsoId === isoSelected.value) {
            const options = response.data.map((s: any) => {
                return { value: s.id, label: s.name };
            });
            setListLocation(options);
        } catch {
            setListLocation([])
            toast.error("Failed to load the Location list. Please try again.");
        } finally {
            setListLoading(false)
        }
    };

    const loadSubsationList = async (isoId: number | null, locationId: number | null) => {
        setListLoading(true);
        try {
            // iso is required
            if (isoId === null) {
                setListSubstation([]);
                return;
            }
            const response = await getAllSubstationsByFilter(sourceId, isoId, locationId);

            const options = response.data.map((s: any) => {
                return { value: s.id, label: s.name };
            });
            // could be empty list of subs
            setListSubstation(options)
        } catch {
            setListSubstation([])
            toast.error("Failed to load the Substation list. Please try again.");
        } finally {
            setListLoading(false)
        }
    };

    const handleIsoChange = (event: any) => {
        setIsoSelected(event);
        setLocationSelected(null);
        loadLocationList(event.value, null);
        setSubstationSelected(null);
        loadSubsationList(event.value, null);
    };
    
    const handleLocationChange = (event: any) => {
        setLocationSelected(event);
        // reload substations on change
        loadSubsationList(isoSelected ? isoSelected.value : null, event ? event.value : null);
    };
    
    const handleSubstationChange = (event: any) => {
        setSubstationSelected(event);
        // reload locations on substation change
        loadLocationList(isoSelected ? isoSelected.value : null, event ? event.value : null)
    };
    const handleNodeNameChange = (event: any) => {
        setNodeNameValue(event);
    };
    // const handlePriceTypeChange = (event: any) => {
    //     setPriceTypeSelected(event);
    // };

    const onKeyPressHandler = () => { }
 
    const onSearch = (event: any) => {
        const x = { regionId: isoSelected?.value,
            locationId: locationSelected?.value,
            substationId: substationSelected?.value,
            nodeInputValue: nodeNameValue,
            nodeActive: isNodeActive
        };
        console.log('x', x)
        
        dispatch(setSearchEntites({ 
            regionId: isoSelected?.value,
            regionName: isoSelected?.label,
            locationId: locationSelected?.value,
            locationName: locationSelected?.label,
            substationId: substationSelected?.value,
            substationName: substationSelected?.label,
            nodeInputValue: nodeNameValue,
            priceType: null,
            nodeActive: isNodeActive
        }));
    };

    useEffect(() => {
        // loadIsoList also resets subs, and locs
        loadIsoList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // loadIsoList also resets subs, and locs
        if (substationSelected) {
            let found = false;
            for (const sub of listSubstation) {
                if (sub.value === substationSelected.value) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                setSubstationSelected(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listSubstation]);

    useEffect(() => {
        // loadIsoList also resets subs, and locs
        if (locationSelected) {
            let found = false;
            for (const loc of listLocation) {
                if (loc.value === locationSelected.value) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                setLocationSelected(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listLocation]);

    return (
        <>
        <DivContainer>
        <>
            <DivContainer50 label="ISO">
                <Select
                    id="isoSelect"
                    onChange={handleIsoChange}
                    options={listIso}
                    value={isoSelected}
                    className='react-select-container'
                    classNamePrefix="react-select"
                    isLoading={isListLoading}
                />
            </DivContainer50>
            <div className={styles.component50}>
                <div className={styles.custom_node_switch}>
                    <label>LMP</label>
                    <Form.Check
                        bsPrefix={styles.custom_node_switch_form_check}
                        type="switch"
                        id="custom-switch"
                        label="Active"
                        checked={isNodeActive}
                        onChange={change => setNodeActive(change.target.checked)}
                    />
                </div>
                <AYPAInput
                    config={{ type: 'text', id: 'nodeNameInput', placeholder: 'Enter LMP Name' }}
                    unit={''}
                    disabled={false}
                    onChangeCallback={handleNodeNameChange}
                    onKeyPressCallback={onKeyPressHandler}
                    hasError={false}
                    errorMessage={nodeNameErrorMessage}
                    initalValue={''}
                />
            </div>
        </>
        </DivContainer>
        <DivContainer>
        <>
            <DivContainer50 label="Location">
                <Select
                    id="locationSelectId"
                    isClearable
                    onChange={handleLocationChange}
                    options={listLocation}
                    value={locationSelected}
                    className='react-select-container'
                    classNamePrefix="react-select"
                    isLoading={isListLoading}
                />
            </DivContainer50>
            <DivContainer50 label="Substation">
                <Select
                    id="substationSelectId"
                    isClearable
                    onChange={handleSubstationChange}
                    options={listSubstation}
                    value={substationSelected}
                    className='react-select-container-2ndCol'
                    classNamePrefix="react-select"
                    isLoading={isListLoading}
                    />
            </DivContainer50>
        </>
        </DivContainer>
        {/* <DivContainer>
        <>
            <DivContainer50 label="Price Type">
                <Select
                    id="priceTypeId"
                    isClearable
                    onChange={handlePriceTypeChange}
                    options={listPriceTypes}
                    value={priceTypeSelected}
                    className='react-select-container'
                    classNamePrefix="react-select"
                    />
            </DivContainer50>
        </>
        </DivContainer> */}
        <DivContainer>
            <div className={classes.actionButton}>
                <DefaultButton primary="primary" title="Search" disabled={false} onClick={() => onSearch(true)} ></DefaultButton>
            </div>
        </DivContainer>
        </>

    )
}

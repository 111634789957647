/**Redux */
import createSagaMiddleware from "redux-saga";

/**Root Middleware */
import rootSaga from "./rootSagas";
import rootReducer from "./rootReducer";
import { webSocketMiddleware } from "./socketMiddleware";

/**States Redux */
import { SimulateChartState } from "store/states/simulate-chart-state";
import { SettingState } from "store/states/settings-state";
import { PriceState } from "store/states/price-state";
import { TableState } from "store/states/table-state";
import { BulkState } from "store/states/bulk-state";
import { WebSocketState } from "store/states/websocket-state";
import { configureStore } from "@reduxjs/toolkit";
import loggerMiddleware from "./loggerMiddleware";

export interface ApplicationState {
    pricesReducer: PriceState,
    settingsReducer: SettingState,
    chartReducer: SimulateChartState,
    tablesReducer: TableState,
    websocketReducer: WebSocketState,
    bulkReducer: BulkState
}

const sagaMiddleware = createSagaMiddleware();

export const setupStore = (preloadedState?: Partial<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat([loggerMiddleware, sagaMiddleware, webSocketMiddleware()]),
        preloadedState
    })
}

const store = setupStore()
sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>

export default store

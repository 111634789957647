import { useEffect } from "react";
import { ApplicationState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { setSimulateButton } from "store/slices/settings-slice";
import { Loading } from "../../../../common/components/loading";
import { SimulationTabs } from "./simulationTabs";
import { useExecuteOperation } from "../hooks/useExecuteOperation";
import { useWaitFixedSchedule } from "../hooks/useWaitFixedSchedule";
import { useInitRunFixedSchedule } from "../hooks/useInitRunFixedSchedule";
import { fetchRunFixedSchedule, cancelOperationTimeOut } from "store/slices/chart-slice";

export const SimulationWrapper = () => {
    /**Contants */
    const dispatch = useDispatch();

    /**Selectors */
    const isLoading = useSelector((store: ApplicationState) => store.chartReducer.isLoading);
    const errorMessage = useSelector((store: ApplicationState) => store.chartReducer.errorMessage);
    const executeOperation = useSelector((store: ApplicationState) => store.chartReducer.executeOperation);
    const simulateButtonclicked = useSelector((store: ApplicationState) => store.settingsReducer.simulateButtonclicked);
    const fixedSchedule = useSelector((store: ApplicationState) => store.settingsReducer.fixedSchedule);

    /** Time Out */
    useWaitFixedSchedule();

    /** RunFixedSchedule */
    useInitRunFixedSchedule();

    /** ExecuteOperation */
    useExecuteOperation();

    useEffect(() => {
        if (simulateButtonclicked && fixedSchedule) {
            dispatch(fetchRunFixedSchedule());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simulateButtonclicked, fixedSchedule]);

    const cancelRequest = () => {
        dispatch(cancelOperationTimeOut());
        setTimeout(() => {
            dispatch(setSimulateButton(false));
        }, 0);
    }

    const loadingMessage =
        <p>
            <b>Generating Results</b><br />
          This may take up to 3 minutes...
        </p>

    return (
        <Loading isLoading={isLoading} errorMessage={errorMessage} showButtonCancel={executeOperation} loadingMessage={loadingMessage} callback={() => cancelRequest()}>
            <SimulationTabs isLoading={isLoading} />
        </Loading>
    );
}

import { ApplicationState } from "store";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LineData } from "common/interfaces/lineTypes";
import AYPALineChart from "common/components/lineChart";
import { getHybridRevenueChart } from "common/adapters/HybridRevenue";
import { setSimulationImage } from "store/slices/settings-slice";
import { Loading } from "common/components/loading";
import { getConfig, formatSimulationData, formatPerfectForesightMean } from "common/components/lineChart/utils";
import { ForesightData } from "common/interfaces/perfectForesightData";
import { five_min_labels, hour_labels } from "common/components/lineChart/values";

interface Props {
    group: Array<string>,
    responseGroupingProp: string
}
export const SimulationChartWrapper = (props: Props) => {

    /**Constants */
    const dispatch = useDispatch();
    const mode = props.group.length === 3 ? props.group[2] : null;

    /**States */
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [lineData, setLineData] = useState<Array<LineData>>();
    const [displayLegend, setDisplayLegend] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState();
    const [chartLabels, setChartLabels] = useState<string[]>(hour_labels);

    /**Selectors */
    const hybridRevenueId = useSelector((store: ApplicationState) => store.chartReducer.hybridRevenueId);
    const perfectForesightId = useSelector((store: ApplicationState) => store.chartReducer.perfectForesightId);
    const settingsReducer = useSelector((store: ApplicationState) => store.settingsReducer);

    const fetchFixedScheduleData = () => {
        const parameters = { id: hybridRevenueId, batteryPower: settingsReducer.batteryPower, group: props.group }
        getHybridRevenueChart(parameters).then((response: any) => {
            const formatedData = formatSimulationData(response.data, mode);
           
            setDisplayLegend(formatedData[0].dataSets.length > 1);
            setLineData(formatedData);
            
        }).catch((error: any) => {
            setErrorMessage(error);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const onCompleteCallback = (chart: any) => {
        // can't download image with multiple charts - !mode
        if (chart?.chart && !mode && !settingsReducer.simulationChartBase64Image) {
            dispatch(setSimulationImage(chart.chart.toBase64Image()));
            chart.chart.update();
        }
    }

    const onCompleteFetchForesightData = ({data} : any) => {
        if (data) {
            let data_array = Array<ForesightData>();
            let hourly_data = true;
            const group_name = props.group.join("_");
            switch (group_name){
                case "hour":
                    data_array = data.hour;
                    break;
                case "hour_month":
                    data_array = data.hour_month;
                    break;
                case "hour_year":
                    data_array = data.hour_year;
                    break;
                case "hour_wint_sum":
                    data_array = data.hour_wint_sum;
                    break;
                case "hour_year_month":
                    data_array = data.hour_year_month;
                    break;
                case "hour_year_wint_sum":
                    data_array = data.hour_year_wint_sum;
                    break;
                case "five_min":
                    data_array = data.five_min;
                    hourly_data = false;
                    break;
                case "five_min_month":
                    data_array = data.five_min_month;
                    hourly_data = false;
                    break;
                case "five_min_year":
                    data_array = data.five_min_year;
                    hourly_data = false;
                    break;
                case "five_min_wint_sum":
                    data_array = data.five_min_wint_sum;
                    hourly_data = false;
                    break;
                case "five_min_year_month":
                    data_array = data.five_min_year_month;
                    hourly_data = false;
                    break;
                case "five_min_year_wint_sum":
                    data_array = data.five_min_year_wint_sum;
                    hourly_data = false;
                    break;
                default:
                    data_array = data.hour;
            }
            if (!hourly_data) {
                setChartLabels(five_min_labels);
            }
            const formatedData = formatPerfectForesightMean(data_array, props.responseGroupingProp, mode);
            setLineData(formatedData);
            setIsLoading(false);
        } 
    }


    useEffect(() => {
        if (hybridRevenueId) {
            fetchFixedScheduleData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hybridRevenueId])

    useEffect(() => {
        if (perfectForesightId) {
            onCompleteFetchForesightData({data: settingsReducer.perfectForesightMeanData});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perfectForesightId])

    return (
        <Loading isLoading={isLoading} errorMessage={errorMessage}>
            <AYPALineChart
                config={getConfig('MW', 'Hour', displayLegend, 'MW')}
                data={lineData}
                mode={mode}
                onCompleteCallback={() => onCompleteCallback}
                labels={chartLabels}
                />
        </Loading>
    )
}

import { useEffect } from "react"
import { ApplicationState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { wsConnect, wsSendMessage } from "store/actions/websocket-action";
import { SourceTypes } from "store/types/source-types";
import { PriceTypes } from "store/types/price-types";
import config from 'config';
import { addDayToDateStr } from "common/utils/utils";
/**
 * useInitRunPriceLookup Hook
 * 
 * A React hook for initiating and managing the process of running price lookup based on websocket connection conditions.
 *
 * @returns {void}
 */

export const useInitRunPriceLookup = (checkAypaToken: Function) => {

    /**Constant */
    const dispatch = useDispatch();

    /**Selectors */
    const executeRunPriceLookup = useSelector((store: ApplicationState) => store.pricesReducer.executeRunPriceLookup);
    const executeRunPriceLookupRaw = useSelector((store: ApplicationState) => store.pricesReducer.executeRunPriceLookupRaw);
    const onOffPeak = useSelector((store: ApplicationState) => store.pricesReducer.onOffPeak);
    const peakStart = useSelector((store: ApplicationState) => store.pricesReducer.peakStart);
    const peakEnd = useSelector((store: ApplicationState) => store.pricesReducer.peakEnd);
    const settingsReducer = useSelector((store: ApplicationState) => store.settingsReducer);
    const socketPrice = useSelector((store: ApplicationState) => store.websocketReducer.socketPrice);
      
    useEffect(() => {
        checkAypaToken();
        if (executeRunPriceLookup && !socketPrice) {
            dispatch(wsConnect(config.priceWs));
        }
        if (executeRunPriceLookup && socketPrice) {
            for (const item of settingsReducer.priceList) {
                // end date is exclusive on backend, add 1 day
                const endDate = item.dateRange ? item.dateRange[1] : item.priceMaxtimestamp;
                const endDateStr = addDayToDateStr(endDate);
                if (item.sourceId === SourceTypes.NRG_STREAM) {
                    // NRG source - use websocket
                    const parameter = {
                        action: "mean-prices-stream",
                        event_id: item.eventId,
                        stream_id: item.streamId,
                        start_timestamp: item.dateRange ? item.dateRange[0] : item.priceMintimestamp,
                        end_timestamp: endDateStr,
                        granularity: item.granularity,
                        iso: item.isoName,
                        on_off_peak: onOffPeak,
                        on_peak_start: peakStart,
                        on_peak_end: peakEnd
                    };
                    const jsonString: string = JSON.stringify(parameter);
                    dispatch(wsSendMessage(jsonString, config.priceWs));
                } else {
                    const parameter: any = {
                        forecast_id: item.forecastId,
                        start_timestamp: item.dateRange ? item.dateRange[0] : item.priceMintimestamp,
                        end_timestamp: endDateStr,
                        event_id: item.eventId
                    };
                    let useNodes = false;
                    // can't call functions inside a react hook
                    if (item.sourceId === SourceTypes.AYPA_NODAL) {
                        useNodes = true
                    }
                    
                    if (useNodes) {
                        parameter["node_id"] = item.nodeId;
                    } else {
                        parameter["location_id"] = item.locationId;
                    }
                    if (onOffPeak) {
                        parameter["on_off_peak"] = true;
                        parameter["on_peak_start"] = peakStart;
                        parameter["on_peak_end"] = peakEnd;
                    }
                    dispatch({ type: PriceTypes.FETCH_INIT_RUN_FORECAST_LOOKUP, parameter });
                }
            }
        }        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeRunPriceLookup, socketPrice]);

    useEffect(() => {
        checkAypaToken();
        if (executeRunPriceLookupRaw && !socketPrice) {
            dispatch(wsConnect(config.priceWs));
        }
        if (executeRunPriceLookupRaw && socketPrice) {
            for (const item of settingsReducer.priceList) {
                // end date is exclusive on backend, add 1 day
                const endDate = item.dateRange ? item.dateRange[1] : item.priceMaxtimestamp;
                const endDateStr = addDayToDateStr(endDate);
                if (item.sourceId === SourceTypes.NRG_STREAM) {
                    // NRG source - use websocket
                    const parameter = {
                        action: "raw-prices-stream",
                        event_id: item.eventId,
                        stream_id: item.streamId,
                        start_timestamp: item.dateRange ? item.dateRange[0] : item.priceMintimestamp,
                        end_timestamp: endDateStr,
                        granularity: item.granularity,
                        iso: item.isoName,
                        on_off_peak: false
                    };
                    const jsonString: string = JSON.stringify(parameter);
                    dispatch(wsSendMessage(jsonString, config.priceWs));
                } else {
                    const parameter: any = {
                        forecast_id: item.forecastId,
                        start_timestamp: item.dateRange ? item.dateRange[0] : item.priceMintimestamp,
                        end_timestamp: endDateStr,
                        event_id: item.eventId
                    };
                    let useNodes = false;
                    // can't call functions inside a react hook
                    if (item.sourceId === SourceTypes.AYPA_NODAL) {
                        useNodes = true
                    }
                    
                    if (useNodes) {
                        parameter["node_id"] = item.nodeId;
                    } else {
                        parameter["location_id"] = item.locationId;
                    }
                    dispatch({ type: PriceTypes.FETCH_INIT_RUN_FORECAST_RAW_LOOKUP, parameter });
                }
            }
        }        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeRunPriceLookupRaw, socketPrice]);

}



import { DivContainer50 } from "common/components/divContainer";
import AYPAInput from "common/components/input";
import { setBatteryPower } from "store/slices/settings-slice";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";

interface Props{
    isLoadingEnabled: boolean,
    isEntitySelected: boolean
}
export const RatedPower = ({ isLoadingEnabled, isEntitySelected }: Props) => {

    /**Selectors */
    const batteryPower = useSelector((store: ApplicationState) => store.settingsReducer.batteryPower);

    /**Contanst */
    const dispatch = useDispatch();
    const labelTitle = "Rated Power";
    const config = { type: 'number', id: 'ratedPower', placeholder: '100', min: '0', max: '1000', step: '10' };
    const unit = "MW";
    const defaultValue = 100;

    const initValue = batteryPower ? batteryPower : defaultValue;

    /**Callback */
    const handlerOnChangeRatedPower = (data: any) => {
        dispatch(setBatteryPower(Number(data)));
    }

    const onKeyPressHandler = () => { }

    return (
        <DivContainer50>
            <AYPAInput
                config={config}
                label={labelTitle}
                unit={unit}
                disabled={!isEntitySelected || isLoadingEnabled}
                onChangeCallback={handlerOnChangeRatedPower}
                onKeyPressCallback={onKeyPressHandler}
                initalValue={initValue}
            />
        </DivContainer50>
    )
}

import classes from './style.module.scss';
import { useAuth } from 'common/hooks/useAuth';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { wsConnect } from '../../store/actions/websocket-action';
import config from 'config';
import { useAxiosInterceptors } from 'common/adapters/api';
import { BulkSettingsSection } from './settingsSection';
import { BulkMainSection } from './mainSection';

interface BulkProps {
    getAypaToken: Function
}

export const BulkPage = ({ getAypaToken }: BulkProps) => {
    // sets function for api to retry if token is expired
    useAxiosInterceptors(getAypaToken);
    const { isValid, getToken } = useAuth({ getAypaToken });
    const dispatch = useDispatch();

    useEffect(() => {
        if (isValid) {
            dispatch(wsConnect(config.priceWs));
            dispatch(wsConnect(config.fixedScheduleWs));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);

    const checkAypaToken = async () => {
        // console.log('check aypa token')
        await getToken();
    };
    return (
        <>
            { isValid &&
                <>
                    <div className={classes.settings}>
                        <BulkSettingsSection />
                    </div>

                    <div className={classes.render}>
                        <BulkMainSection checkAypaToken={checkAypaToken}/>
                    </div>
                </>
            }
        </>
    )
}


            // const parameter = {
            //     action: "top-bottom",
            //     event_id: "REMOVE",
            //     iso: "ERCOT",
            //     rated_power: settingsReducer.batteryPower,
            //     duration: settingsReducer.batteryHour,
            //     roundtrip_efficiency: rre/100,
            //     cycles_per_day: settingsReducer.cycleCount,
            //     // start_date: formatDate(startTimestamp),
            //     start_date: "2015-01-06",
            //     end_date: formatDate(endDateStr),
            //     granularity: "FIVEMIN_HOURLY",
            //     stream_id: price.streamId,
            //     forecast_location: settingsReducer.forecastKey,
            //     node_name: node_name
            // };
import  { useEffect, useState } from "react";
import classes from "./../style.module.scss";
import { useAccordionButton } from "react-bootstrap"
import { ApplicationState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { setBulkActiveCard } from "store/slices/bulk-slice";


export const BulkCustomToggle = (props: any) => {
    const dispatch = useDispatch();
    const bulkReducer = useSelector((store: ApplicationState) => store.bulkReducer);
    const [currentKey, setCurrentKey] = useState<any>('0');
    /**SELECTORS */
    const onToggleHandler = useAccordionButton(bulkReducer.activeCard);
    const doToggle = (key: any) => {
      if (currentKey !== key) {
          props.stack.openCardId = key;
          setCurrentKey(key);
          onToggleHandler(currentKey);
          dispatch(setBulkActiveCard(key));
      }
    }

    const onClickHandler = (e: any) => {
      doToggle(props.eventKey);
    }

    useEffect(() => {
        if (bulkReducer.getPriceListButtonClicked && bulkReducer.activeCard === '0' && props.eventKey !== '0') {
            doToggle('0');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bulkReducer.getPriceListButtonClicked]);

    useEffect(() => {
        if (bulkReducer.activeCard !== currentKey) {
            doToggle(bulkReducer.activeCard);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bulkReducer.activeCard, doToggle]);

    const isActive = bulkReducer.activeCard === props.eventKey ? 'active' : '';

    return (
        <div onClick={onClickHandler} className={`${classes.header} ${isActive}`} >
            {props.children}
        </div>
    );
}

import { WebsocketTypes } from 'store/types/websocket-types';
import config from 'config';
import { wsMessage } from 'store/actions/websocket-action';
import { wsConnected, wsDisconnected } from 'store/slices/websocket-slice';


export const webSocketMiddleware = () => {
    let socketPrice: any = null; // Add/update types
    let socketFixedSchedule: any = null;

    const onOpen = (store: any) => (event: any) => {
        // console.log('websocket open', event.target.url);
        store.dispatch(wsConnected(event.target.url));
    };

    const onClose = (store: any) => (event: any) => {
        // console.log('websocket onclose', event)
        if (event.target.url !== "") {
            store.dispatch(wsDisconnected(event.target.url));
        }
    };

    const onMessage = (store: any) => (event: any) => {
        // console.log('websocket onmessage', event)
        if (event.data !== "") {
            store.dispatch(wsMessage(event.data, event.target.url))
        }
    };

    // the middleware part of this function
    return (store: any) => (next: any) => (action: any) => {
        // console.log('webSocketMiddleware actions', action.type)
        switch (action.type) {
            case WebsocketTypes.WS_CONNECT:
                // console.log('webSocketMiddleware ws connect', action.payload.host);
                if (action.payload.host.includes(config.priceWs)) {
                    if (socketPrice !== null) {
                        socketPrice.close();
                    }
                    // connect to the remote host 
                    try {
                        let accessToken = window.sessionStorage.getItem(config.keyApiSession);
                        socketPrice = new WebSocket(action.payload.host + '?authorization=' + accessToken);
                        // websocket handlers
                        socketPrice.onmessage = onMessage(store);
                        socketPrice.onclose = onClose(store);
                        socketPrice.onopen = onOpen(store);
                    } catch (err) {
                        console.error('websocket failed', err);
                        socketPrice = null;
                    }
                } else {
                    if (socketFixedSchedule !== null) {
                        socketFixedSchedule.close();
                    }
                    // connect to the remote host 
                    try {
                        let accessToken = window.sessionStorage.getItem(config.keyApiSession);
                        socketFixedSchedule = new WebSocket(action.payload.host + '?authorization=' + accessToken);
                        // websocket handlers
                        socketFixedSchedule.onmessage = onMessage(store);
                        socketFixedSchedule.onclose = onClose(store);
                        socketFixedSchedule.onopen = onOpen(store);
                    } catch (err) {
                        console.error('websocket failed', err);
                        socketFixedSchedule = null;
                    }
                }


                break;
            case WebsocketTypes.WS_SEND_MESSAGE:
                // console.log('webSocketMiddleware send message', action.payload.host)
                if (action.payload.host.includes(config.priceWs)) {
                    socketPrice.send(action.payload.value);
                } else {
                    socketFixedSchedule.send(action.payload.value);
                }
                break;
            case WebsocketTypes.WS_DISCONNECT:
                // console.log('webSocketMiddleware WS_DISCONNECT', action.payload)
                if (action.payload.host.includes(config.priceWs)) {
                    socketPrice.close();
                } else {
                    socketFixedSchedule.close();
                }
                break;
            default:
                //   console.log('the next action:', action);
                return next(action);
        }
    };
};
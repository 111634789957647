import { combineReducers } from "redux";
import pricesReducer from "store/slices/prices-slice";
import settingsReducer from "store/slices/settings-slice"
import chartReducer from "store/slices/chart-slice"
import tablesReducer from "store/slices/table-slice"
import websocketReducer from "store/slices/websocket-slice"
import bulkReducer from "store/slices/bulk-slice"

export default combineReducers({
    tablesReducer,
    pricesReducer,
    settingsReducer,
    chartReducer,
    websocketReducer,
    bulkReducer
});

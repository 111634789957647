import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "store";
import sprites from "common/assets/img/sprites.svg";
import SpinnerButton from "../../../../../common/components/loading/sprinnerButton";
import classes from "./../../style.module.scss";
import { DataToFileDownload, addDayToDateStr, createMetadataPrice } from "common/utils/utils";
import { requestFailed } from "store/slices/prices-slice";
import { setCSVDownloading, successOperationPrice } from "store/slices/prices-slice";
import { wsConnect, wsSendMessage } from "store/actions/websocket-action";
import { SourceTypes } from "store/types/source-types";
import config from 'config';
import { setGetPriceButton } from "store/slices/settings-slice";

export const ButtonDownloadPriceProfileCsv = () => {
    /**SELECTORS */
    const settingsReducer = useSelector((store: ApplicationState) => store.settingsReducer);
    const pricesReducer = useSelector((store: ApplicationState) => store.pricesReducer);
    const socketPrice = useSelector((store: ApplicationState) => store.websocketReducer.socketPrice);

    /**CONSTANTS */
    const dispatch = useDispatch();
    let titleButton = 'Download as .CSV';
    const downloadTypeStreams = "extractStreams";
    const downloadAsCSV = (event: any) => {
        dispatch(setCSVDownloading({isCSVDownloading: true, downloadType: downloadTypeStreams}));
    }
    const timeoutTime = 180000; //3min update error message to match

    const [timeOutId, setTimeOutId] = useState<any>(null);

    useEffect(() => {
        if (pricesReducer.isCSVDownloading && pricesReducer.downloadType === downloadTypeStreams && !socketPrice) {
            dispatch(wsConnect(config.priceWs));
        }
        if (pricesReducer.isCSVDownloading && pricesReducer.downloadType === downloadTypeStreams && socketPrice && settingsReducer.priceList.length > 0) {
            const streams: Array<any> = [];
            settingsReducer.priceList.forEach(price => {
                const endDate = price.dateRange ? price.dateRange[1] : price.priceMaxtimestamp;
                const endDateStr = addDayToDateStr(endDate);
                if (price.sourceId === SourceTypes.NRG_STREAM) {
                    streams.push({
                        stream_id: price.streamId,
                        start_timestamp: price.dateRange ? price.dateRange[0] : price.priceMintimestamp,
                        end_timestamp: endDateStr,
                        granularity: price.granularity,
                        timezone: settingsReducer.priceTimezone,
                        iso: price.isoName
                    });
                } else {
                    streams.push({
                        forecast_id: price.forecastId,
                        start_timestamp: price.dateRange ? price.dateRange[0] : price.priceMintimestamp,
                        end_timestamp: endDateStr,
                        node_id: price.nodeId ? price.nodeId : null,
                        location_id: price.locationId ? price.locationId : null
                    });
                }
            })
            let parameter: any = {
                action: "extract-streams",
                type: getDownloadType(),
                streams: streams,
                event_id: "AAA",
                on_off_peak: pricesReducer.onOffPeak,
                on_peak_start: pricesReducer.peakStart,
                on_peak_end: pricesReducer.peakEnd
            }
            const jsonString: string = JSON.stringify(parameter);
            dispatch(wsSendMessage(jsonString, config.priceWs));

            if (timeOutId) {
                clearTimeout(timeOutId)
                setTimeOutId(null);
            }
            const timeOut = setTimeout(() => {
                dispatch(requestFailed({ "message": "Request timed out.", "details": "Request took longer than 3mins." }));
                dispatch(setGetPriceButton(false));
            }, timeoutTime);
            setTimeOutId(timeOut)

        } else {
            setTimeOutId(null);
            clearTimeout(timeOutId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricesReducer.isCSVDownloading, socketPrice, pricesReducer.downloadType]);

    useEffect(() => {
        if (pricesReducer.completedDownloadCSV && pricesReducer.downloadType === downloadTypeStreams) {
            const loadedData = pricesReducer.loadedData;
            let priceJson = null;
            try {
                // only errors are in json
                priceJson = JSON.parse(loadedData)
            } catch (e) {
                priceJson = null;
            }
            if (priceJson) {
                responseDoneWithError(priceJson);
            } else {
                responseWithoutError(loadedData);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricesReducer.completedDownloadCSV, pricesReducer.downloadType]);

    const responseDoneWithError = (data: any) => {
        console.error("Error on download: ", data.error)
        dispatch(requestFailed({ message: data.error }));
        dispatch(setGetPriceButton(false));
    }

    const responseWithoutError = (data: any) => {
        // convert data to csv and save
        let filename = getFilename();
        data = createMetadataPrice(settingsReducer, pricesReducer.onOffPeak, false) + "\n" + data;
        DataToFileDownload(data, filename);
        dispatch(successOperationPrice(data));
    }

    function getDownloadType(): string {
        let type = "OVERALL_MEAN"
        const isHourly = settingsReducer.priceList[0].granularity !== "FIVEMIN";
        switch (settingsReducer.priceTab){
            case "overall":
                if (isHourly) {
                    type = "OVERALL_MEAN";
                } else {
                    type = "OVERALL_MEAN_5MIN";
                }
                break;
            case "monthly":
                if (isHourly) {
                    type = "OVERALL_MEAN_HOUR_MONTH";
                } else {
                    type = "OVERALL_MEAN_5MIN_MONTH";
                }
                break;
            case "wint_sum":
                if (isHourly) {
                    type = "OVERALL_MEAN_SEASONAL";
                } else {
                    type = "OVERALL_MEAN_5MIN_SEASONAL";
                }
                break;
            case "yearly":
                if (isHourly) {
                    type = "OVERALL_MEAN_HOUR_YEAR";
                } else {
                    type = "OVERALL_MEAN_5MIN_YEAR";
                }
                break;
            case "wint_sum_by_year":
                if (isHourly) {
                    type = "OVERALL_MEAN_SEASONAL_YEAR";
                } else {
                    type = "OVERALL_MEAN_5MIN_SEASONAL_YEAR";
                }
                break;
            case "month_by_year":
                if (isHourly) {
                    type = "OVERALL_MEAN_MONTH_YEAR";
                } else {
                    type = "OVERALL_MEAN_5MIN_MONTH_YEAR";
                }
                break;
            case "raw":
                type = "RAW";
                break;
            default:
                type = "OVERALL_MEAN";
                break;
        }
        return type;
    }

    function getFilename(): string {
        let filename = "price"
        switch (settingsReducer.priceTab){
            case "overall":
                filename += "_overall";
                break;
            case "monthly":
                filename += "_monthly";
                break;
            case "wint_sum":
                filename += "_seasonally";
                break;
            case "yearly":
                filename += "_yearly";
                break;
            case "wint_sum_by_year":
                filename += "_season_by_year";
                break;
            case "month_by_year":
                filename += "_month_by_year";
                break;
            case "raw":
                filename += "_raw";
                break;
            default:
                filename += "_overall";
                break;
        }
        return filename;
    }

    return (
        <div className={classes.card__dropdownitem}>
            <button onClick={downloadAsCSV}
                disabled={pricesReducer.executeRunPriceLookup || pricesReducer.isCSVDownloading}>
                {pricesReducer.isCSVDownloading && pricesReducer.downloadType === downloadTypeStreams && <SpinnerButton />}
                {!pricesReducer.isCSVDownloading && <span>
                    <svg>
                        <use xlinkHref={`${sprites}#icon-export`}></use>
                    </svg>
                </span>}
                {titleButton}
            </button>
        </div>
    )
}

import { DivContainer, DivContainer50 } from "common/components/divContainer";
import { Price } from "../../../../common/aypaForms/price";
import { Interval } from "../../../../common/aypaForms/interval";

interface Props {
    isLoadingEnabled: boolean
}
export const PriceMetadata = ({ isLoadingEnabled }: Props) => {

    /**Constants */
    const labelPrice = "Price";
    const labelInterval = "Interval";

    return (

        <DivContainer>
            <>
                <DivContainer50 label={labelPrice}>
                    <Price isLoadingEnabled={isLoadingEnabled} />
                </DivContainer50>

                <DivContainer50 label={labelInterval}>
                    <Interval isLoadingEnabled={isLoadingEnabled} />
                </DivContainer50>
            </>
        </DivContainer>
    )
}
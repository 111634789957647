import { getAllForecastByFilter } from "common/adapters/entities"
import { ForecastOption, customStylesWithPadding } from "common/components/selectComponents"
import { getLocalizedDate } from "common/utils/utils"
import { setForecastData } from "store/slices/settings-slice"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import { ApplicationState } from "store"
import toast from 'react-hot-toast';


interface Props {
    isLoadingEnabled: boolean
}

export const Forecast = ({ isLoadingEnabled }: Props) => {

    const dispatch = useDispatch()

    const sourceId = useSelector((store: ApplicationState) => store.settingsReducer.sourceId);
    const regionId = useSelector((store: ApplicationState) => store.settingsReducer.regionId);

    const [listForecast, setListForecast] = useState<Array<any>>([]);
    const [forecastSelected, setForecastSelected] = useState<Array<any>>([]);
    const [isListLoading, setListLoading] = useState<boolean>(true);

    const handlerOnChangeForecast = (selectedOption: any) => {
        if (selectedOption !== null) {
            setForecastSelected(selectedOption)
            const forecast_data = {
                id: selectedOption.value,
                priceMaxTimestamp: selectedOption.max_timestamp,
                priceMinTimestamp: selectedOption.min_timestamp,
                name: selectedOption.label,
                inflationVector: selectedOption.inflation_vector
            }
            dispatch(setForecastData(forecast_data))
        }
    }

    const loadForecastList = async () => {
        try {
            if (regionId) {

                const response = await getAllForecastByFilter(sourceId, regionId);
                const options = response.data.items.map((s: any) => {
                    return {
                        value: s.id, label: s.name,
                        inflation_vector: s.inflation_vector,
                        min_timestamp: getLocalizedDate(s.min_timestamp, "", false),
                        max_timestamp: getLocalizedDate(s.max_timestamp, "", false),
                        timestamp_created: s.timestamp_created
                    }
                })
                setListForecast(options)
            }
        } catch {
            setListForecast([])
            toast.error("Failed to load the Forecast list. Please try again.");
        } finally {
            setListLoading(false)
        }
    }

    useEffect(() => {
        if (forecastSelected) {
            setForecastSelected([])
            const forecast_data = {
                id: null,
                priceMaxTimestamp: null,
                priceMinTimestamp: null,
                name: null,
                inflationVector: null
            }
            dispatch(setForecastData(forecast_data))
        }
        if (regionId) {
            loadForecastList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regionId])


    return (
        <>
            <Select
                id="forecastDropdown"
                onChange={handlerOnChangeForecast}
                options={listForecast}
                value={forecastSelected}
                className='react-select-container-2ndCol'
                classNamePrefix="react-select"
                components={{ Option: ForecastOption }}
                styles={customStylesWithPadding}
                menuPlacement='auto'
                isDisabled={isLoadingEnabled}
                isLoading={isListLoading}
            />
        </>
    )
}
import  { useEffect, useState } from "react";
import classes from "./../style.module.scss";
import { useAccordionButton } from "react-bootstrap"
import { ApplicationState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { setActiveCard } from "store/slices/settings-slice";


export const CustomToggle = (props: any) => {
    const dispatch = useDispatch();
    const chartSettings = useSelector((store: ApplicationState) => store.settingsReducer);
    const [currentKey, setCurrentKey] = useState<any>('0');
    /**SELECTORS */
    const onToggleHandler = useAccordionButton(chartSettings.activeCard);
    const doToggle = (key: any) => {
      if (currentKey !== key) {
          props.stack.openCardId = key;
          setCurrentKey(key);
          onToggleHandler(currentKey);
          dispatch(setActiveCard(key));
      }
    }

    const onClickHandler = (e: any) => {
      doToggle(props.eventKey);
    }

    useEffect(() => {
        if (chartSettings.getPriceButtonclicked && chartSettings.activeCard === '0' && props.eventKey !== '0') {
            doToggle('0');
        } else if (chartSettings.simulateButtonclicked && chartSettings.activeCard === '1' && props.eventKey !== '1') {
            doToggle('1');
        } else if (chartSettings.simulateButtonclicked && chartSettings.activeCard === '2' && props.eventKey !== '2') {
            doToggle('2');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartSettings.getPriceButtonclicked, chartSettings.simulateButtonclicked]);

    useEffect(() => {
        if (chartSettings.activeCard !== currentKey) {
            doToggle(chartSettings.activeCard);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartSettings.activeCard, doToggle]);

    const isActive = chartSettings.activeCard === props.eventKey ? 'active' : '';

    return (
        <div onClick={onClickHandler} className={`${classes.header} ${isActive}`} >
            {props.children}
        </div>
    );
}

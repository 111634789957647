import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import classes from './App.module.scss';
import "./index.scss";


import ErrorMessage from './common/ErrorMessage';
import withAuthProvider, { AuthComponentProps } from './common/auth/AuthProvider';

import AYPALogin from "./common/components/login";
import AYPAHeader from "./common/components/header";
import AYPAFooter from "./common/components/footer";
import { HomePage } from 'pages/home';
import { Toaster } from 'react-hot-toast';
import { AdminToolsPage } from 'pages/adminTools';
import { NotFoundPage } from 'pages/notFound';
import { ReleaseNotes } from 'pages/releaseNotes';
import { BulkPage } from 'pages/bulk';


const color_gradient_2 = '#d1232a'
class App extends Component<AuthComponentProps> {

  render() {
    if (this.props.error) {
      <ErrorMessage
        message={this.props.error.message}
        debug={this.props.error.debug} />;
    }

    if (!this.props.isLoading && !this.props.isAuthenticated) {
      return (
        <AYPALogin login={this.props.login} />
      )
    } else {
      return (
        <>
          <Toaster
            position="top-center"
            toastOptions={{ style: { maxWidth: '1000px' }, iconTheme: { primary: color_gradient_2, secondary: '' } }}
            // react-hot-toast doesn't allow passing icon colors via scss classes.
            // We are using the above options as a temporary solution. This needs to be fixed at a later point.
          />
          <div className={classes.home}>
            <AYPAHeader
              user={this.props.user}
              authButtonMethod={this.props.logout}
            />
            <div className={classes.content}>
              <Router>
                <Routes>
                <Route path="/"
                  element={<HomePage getAypaToken={this.props.getAypaToken} />
                  }
                />
                 <Route path="/adminTools"
                  element={<AdminToolsPage getAypaToken={this.props.getAypaToken} />
                  }
                />
                <Route path="/releaseNotes"
                  element={<ReleaseNotes getAypaToken={this.props.getAypaToken} />
                  }
                />
                <Route path="/bulk"
                  element={<BulkPage getAypaToken={this.props.getAypaToken} />
                  }
                />
                <Route
                  element={<NotFoundPage getAypaToken={this.props.getAypaToken} />
                  }
                />
                </Routes>
              </Router>
            </div>
            <AYPAFooter />
          </div>
        </>
      );
    }
  }
}

export default withAuthProvider(App);

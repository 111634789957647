
import { Table } from "react-bootstrap";
import { ApplicationState } from "store";
import { useSelector } from "react-redux";

import classes from "./../../style.module.scss";
import { Loading } from "../../../../../common/components/loading";
import { useFixedScheduleSummary } from "../../hooks/useFixedScheduleSummary";

export const SummaryTable = () => {

    const fixedSchedule = useSelector((store: ApplicationState) => store.settingsReducer.fixedSchedule);
    const { isLoading, points, errorMessage } = useFixedScheduleSummary();
    const tbody = [];

    if (points) {
        let i = 0;
        for (const item of points) {
            tbody.push(<tr key={'id' + i}>
                <td>{item.charge_start}</td>
                <td>{item.discharge_start}</td>
                <td>{new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 2 }).format(item.total_revenue)}</td>
                <td>{item.percent_max_revenue}</td>
            </tr>);
            i++;
        }
    }

    return (
        <>
            { fixedSchedule &&
                <>
                    <div className={classes.scheduleSearchSection}>
                        <div className={classes.sectionTitle}><b>Schedule Search</b></div>
                        <div className={classes.tableContainer}>
                            <Loading isLoading={isLoading} errorMessage={errorMessage}>
                                <>
                                    <Table striped hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Charge Start</th>
                                                <th>Discharge Start</th>
                                                <th>Total Revenue $</th>
                                                <th>% of Max Revenue</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tbody}
                                        </tbody>
                                    </Table>
                                </>
                            </Loading>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

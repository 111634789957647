import { useState } from "react";
import { useDispatch } from "react-redux";

import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import sprites from "common/assets/img/sprites.svg";
import classes from "common/components/divContainer/style.module.scss";
import { DivContainer, DivContainer50 } from "../divContainer";


export const DateRange = ({onSetDateRange, onDateError, initStartDate, initEndDate}: any) => {
    /**Constants */
    const dispatch = useDispatch();

    /**States */
    
    const [startDate, setStartDate] = useState<Date | null>(initStartDate);
    const [endDate, setEndDate] = useState<Date | null>(initEndDate);
    const [minDate, setMinDate] = useState<any>(null);
    const [maxDate, setMaxDate] = useState<any>(null);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrMessage] = useState<string>("TEST"); 

    const validate = (start: Date | null, end: Date | null) => {
        let showErr = false;
        let errorMessage = "";
        // must limit 5 min data requests
        if (start != null && end != null) {
            const diffTime = end.getTime() - start.getTime();
            // account for leap year
            const oneYearsInMilliseconds = 31536000000 + 86400000;
            if (diffTime < 0) {
                showErr = true;
                errorMessage ="End Date is before Start Date";
            }else if (diffTime >= oneYearsInMilliseconds) {
                showErr = true;
                errorMessage ="Max range is 1 year";
            }
        }
        onDateError(showErr);
        setShowError(showErr);
        setErrMessage(errorMessage);
    }

    const updateStartDate = (date: any) => {
        validate(date, endDate);
        setStartDate(date);
        onSetDateRange(date, endDate);
    }

    const updateEndDate = (date: any) => {
        validate(startDate, date);
        setEndDate(date);
        onSetDateRange(startDate, date);
    }

    return (
        <>
        <DivContainer>
            <>
            <DivContainer50 label="Start">
                <>
                <DatePicker 
                    className={classes.datepicker}
                    minDate={minDate}
                    maxDate={maxDate}
                    dateFormat="MM/dd/yyyy"
                    selected={startDate}
                    disabled={isDisabled}
                    onChange={date => date && updateStartDate(date)}
                    placeholderText="Start date"
                />
                <span>
                    <svg>
                        <use xlinkHref={`${sprites}#icon-calendar`}></use>
                    </svg>
                </span>
                </>
            </DivContainer50>
            <DivContainer50 label="End">
                <>
                <DatePicker className={classes.datepicker}
                    minDate={minDate}
                    maxDate={maxDate}
                    dateFormat="MM/dd/yyyy"
                    selected={endDate}
                    disabled={isDisabled}
                    onChange={date => date && updateEndDate(date)}
                    placeholderText="End date"
                />
                <span>
                    <svg>
                        <use xlinkHref={`${sprites}#icon-calendar`}></use>
                    </svg>
                </span>
                </>
            </DivContainer50>
            </>
        </DivContainer>
        { showError && <span className="error">{errorMessage}</span>}
        </>

    )
}

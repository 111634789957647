import classes from "./header.module.scss"
import sprites from "./../../assets/img/sprites.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft, faBucket, faHouse, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';


const Header = (props: any) => {
        return (
        <div className={classes.header}>
            <div className={classes.header__logo}>
                <div className={classes.header__logo__img}>
                    <svg>
                        <use xlinkHref={`${sprites}#logo`}></use>
                    </svg>
                </div>
                <div className={classes.header__routes}>
                    <span><a href="/">
                        <FontAwesomeIcon icon={faHouse} />
                        Home
                    </a></span>
                    <span><a href="/bulk">
                        <FontAwesomeIcon icon={faBucket} />
                        Bulk
                    </a></span>
                    <span><a href="/releaseNotes">
                        <FontAwesomeIcon icon={faClockRotateLeft} />
                        Release Notes
                    </a></span>
                </div>

            </div>
            <div className={classes.header__menu}>
                <div className={classes.header__menu__item}>
                    <span><a href="/" onClick={props.authButtonMethod}>
                        <div className={classes.header__menu__item__iconbox}>
                        <FontAwesomeIcon icon={faArrowRightFromBracket} />
                        <span>Log Out</span>
                    </div></a>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Header;

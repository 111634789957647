import { Table } from "reactstrap";
import { ApplicationState } from "store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import classes from "./../../style.module.scss";
import { formatCurrency } from "common/utils/utils";
import { months } from "common/components/lineChart/values";
import { NodeResults, SumTopBottomData, TopBottomRevenueData } from "common/interfaces/topBottomData";
import React from "react";

interface rowData {
    [key: string]: {name: string, data: Array<TopBottomRevenueData>}
}
interface tableProps {
    group: string
}
export const TopBottomTable = ({ group }: tableProps) => {
    const topBottomSummaryData: {[key: string]: SumTopBottomData} = useSelector((store: ApplicationState) => store.bulkReducer.topBottomSummaryData);
    const bulkPriceList: Array<NodeResults> = useSelector((store: ApplicationState) => store.bulkReducer.bulkPriceList);
    const [tableHeader, setTableHeader] = useState<JSX.Element | null>(null);
    const [tableBody, setTableBody] = useState<JSX.Element[] | null>(null);

    const onCompleteFetchTopBottomtData = (dataObjects: {[key: string]: SumTopBottomData}) => {
        const dataObj: rowData = {};
        // find min and max dates
        let minYear = null;
        let maxYear = null;
        let minMonth = null;
        let maxMonth = null;
        // obj for all streams and nodes.  need to group by node for streams
        for (const key in dataObjects) {
            // find matching node in bulkPriceList
            let matchingPriceList: NodeResults | null = null;
            // day ahead is true, real time is false, null is nodal
            let dayAhead: boolean | null = null;
            for (const price of bulkPriceList) {
                if (price.streams.length > 0) {
                    let foundMatch = false;
                    for (const stream of price.streams) {
                        if (stream.eventId === key) {
                            matchingPriceList = price;
                            dayAhead = stream.priceType === "DAY-AHEAD";
                            foundMatch = true;
                            break;
                        }
                    }
                    if (foundMatch) {
                        break;
                    }
                } else if (price.eventId === key) {
                    matchingPriceList = price;
                    break;
                }
            }
            if (matchingPriceList === null) {
                continue;
            }
            const data: SumTopBottomData = dataObjects[key];
            switch (group) {
                case "total":
                    if (matchingPriceList.eventId in dataObj) {
                        // only NRG can have DA / RT
                        if (dayAhead) {
                            dataObj[matchingPriceList.eventId].data[0].tb_da = data.total.top_bottom_revenue_per_kwmonth
                        } else {
                            dataObj[matchingPriceList.eventId].data[0].tb_rt = data.total.top_bottom_revenue_per_kwmonth
                        }
                    } else {
                        const totalNew: TopBottomRevenueData = { ...data.total, tb_da: null, tb_rt: null };
                        if (dayAhead) {
                            totalNew.tb_da = data.total.top_bottom_revenue_per_kwmonth
                        } else {
                            totalNew.tb_rt = data.total.top_bottom_revenue_per_kwmonth
                        }
                        dataObj[matchingPriceList.eventId] = {name: data.name, data: [totalNew]}
                    }
                    break;
                case "monthly":
                    if ((minMonth === null) || minMonth > data.monthly[0].month) {
                        minMonth = data.monthly[0].month;
                    }
                    if ((maxMonth === null) || maxMonth < data.monthly[data.monthly.length - 1].month) {
                        maxMonth = data.monthly[data.monthly.length - 1].month;
                    }
                    if (matchingPriceList.eventId in dataObj) {
                        // only NRG can have DA / RT
                        data.monthly.forEach(x => {
                            // complier thinks matchingPriceList' is possibly 'null'.  it is checked before switch 
                            const monthData = dataObj[matchingPriceList!.eventId].data.find(y => y.month === x.month);
                            if (monthData) {
                                if (dayAhead) {
                                    monthData.tb_da = x.top_bottom_revenue_per_kwmonth;
                                } else {
                                    monthData.tb_rt = x.top_bottom_revenue_per_kwmonth;
                                }
                            } else {
                                // no matching month - make new ?
                                if (dayAhead) {
                                    dataObj[matchingPriceList!.eventId].data.push({ ...x, tb_da: x.top_bottom_revenue_per_kwmonth, tb_rt: null });
                                } else {
                                    dataObj[matchingPriceList!.eventId].data.push({ ...x, tb_da: null, tb_rt: x.top_bottom_revenue_per_kwmonth });
                                }
                            }
                        });
                    } else {
                        const monthlyNew: Array<TopBottomRevenueData> = [];
                        data.monthly.forEach(x => {
                            monthlyNew.push({ ...x, tb_da: null, tb_rt: null });
                        })
                        if (dayAhead) {
                            monthlyNew.forEach(x => {
                                x.tb_da = x.top_bottom_revenue_per_kwmonth;
                              });
                        } else {
                            monthlyNew.forEach(x => {
                                x.tb_rt = x.top_bottom_revenue_per_kwmonth;
                              });
                        }
                        dataObj[matchingPriceList.eventId] = {name: data.name, data: monthlyNew}
                    }
                    break;
            }
        }
        // get header values - Total (total), Jan Feb Mar..etc (monthly), 2024, 2023 (yearly)
        // some data might not have all the data.  need full range.  stream1=Jan, Feb, Mar. stream2=May Jun July. full header = Jan - Jul
        let yearRange: Array<number> = [];
        let monthRange: Array<number> = [];
        if (minYear && maxYear) {
            for (let year = minYear; year <= maxYear; year++) {
                yearRange.push(year);
            }
        }
        if (minMonth && maxMonth) {
            for (let month = minMonth; month <= maxMonth; month++) {
                monthRange.push(month);
            }
        }
        switch (group) {
            case "total":
                genTableTotal(dataObj);
                break;
            case "monthly":
                genTableMonthly(dataObj, monthRange);
                break;
        }
    }

    function genTableTotal(data: rowData){
        setTableHeader(
            <tr>
                <th>Node Name</th>
                <th>DA</th>
                <th>RT</th>
            </tr>
        );
        setTableBody(
            (Object.values(data).map((item, index) => (
                <tr key={index}>
                    <td>{item.name}</td>
                    {item.data.map((item2, index2) => (
                        <React.Fragment key={index + "_" + index2}>
                            <td key={index+"_"+index2+"da"}>{item2.tb_da ? formatCurrency(item2.tb_da) : '-'}</td>
                            <td key={index+"_"+index2+"rt"}>{item2.tb_rt ? formatCurrency(item2.tb_rt) : '-'}</td>
                        </React.Fragment>
                    ))}
                </tr>
            )))
        );
    }
    function genTableMonthly(data: rowData, monthRange: Array<number>) {
        setTableHeader(
            <tr>
                <th>Node Name</th>
                {monthRange.map((month) => (
                    <React.Fragment key={month}>
                    <th key={month+"da"}>DA {months[month - 1]}</th>
                    <th key={month+"rt"}>RT {months[month - 1]}</th>
                    </React.Fragment>
                ))}
            </tr>
        );
        setTableBody(
            (Object.values(data).map((item, index) => (
                <tr key={index+"gg"}>
                    <td>{item.name}</td>
                    {monthRange.map(month => {
                        const monthData = item.data.find(y => y.month === month);
                        return <React.Fragment key={month}>
                            <td key={month+'da'}>{monthData && monthData.tb_da ? formatCurrency(monthData.tb_da) : '-'}</td>
                            <td key={month+'rt'}>{monthData && monthData.tb_rt ? formatCurrency(monthData.tb_rt) : '-'}</td>
                            </React.Fragment>;
                    })}
                </tr>
            )))
        );
    }

    useEffect(() => {
        if (Object.keys(topBottomSummaryData).length !== 0) {
            onCompleteFetchTopBottomtData(topBottomSummaryData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topBottomSummaryData])

    return (
        <Table striped hover responsive className={classes.topBottomTable}>
            <thead>
                {tableHeader}
            </thead>
            <tbody>
                {tableBody}
            </tbody>
        </Table>
    )
}
import { useEffect } from "react"
import { ApplicationState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { SimulateChartTypes } from "store/types/simulate-chart-types";

export const useInitRunFixedSchedule = () => {
    /**Constant */
    const dispatch = useDispatch();

    /**Selectors */
    const executeRunFixedSchedule = useSelector((store: ApplicationState) => store.chartReducer.executeRunFixedSchedule);
    const settingsReducer = useSelector((store: ApplicationState) => store.settingsReducer);    

    useEffect(() => {

        if (executeRunFixedSchedule) {
            dispatch({ type: SimulateChartTypes.FETCH_INIT_RUN_FIXED_SCHEDULE, settingsReducer: { ...settingsReducer } });
        }        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeRunFixedSchedule]);
}

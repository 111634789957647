import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";

import classes from "./../../style.module.scss";
import { HelpHover } from "common/components/helpButton";
import { TopBottomTable } from "./topBottomTable";
import { SumTopBottomData } from "common/interfaces/topBottomData";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";

export const TopBottomTableTabs = () => {

    /**Constants */
    const initHelp = ["price is in kWm = kilowatt-months", "DA = Day Ahead", "RT = Real Time"]

    const topBottomSummaryData: {[key: string]: SumTopBottomData} = useSelector((store: ApplicationState) => store.bulkReducer.topBottomSummaryData);

    /**UseStates */
    const [key, setKey] = useState('total');

    const setKeyHandler = (newkey: any) => {
        setKey(newkey);
    }

    return (
        <div >
            <div className={classes.sectionTitleWithHelp}>
                <div className={classes.sectionTitle}><b>Top Bottom</b></div>
                <HelpHover messageList={initHelp} largeMarg={false}></HelpHover>
            </div>

            {Object.keys(topBottomSummaryData).length > 0 && 
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k: any) => setKeyHandler(k)}
                justify>
                <Tab eventKey="total" title="Total">
                    {key === 'total' &&
                        <div className={classes.tabTableContent}>
                            <TopBottomTable group={'total'} />
                        </div>
                    }
                </Tab>
                <Tab eventKey="monthly" title="Monthly">
                    {key === 'monthly' &&
                        <div className={classes.tabTableContent}>
                            <TopBottomTable group={'monthly'} />
                        </div>
                    }
                </Tab>
            </Tabs>
            }
        </div>
    )
}

import { useEffect, useState } from "react"
import Select from 'react-select';
import { getAllSource } from "common/adapters/entities";
import { DivContainer, DivContainer100 } from "common/components/divContainer";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { setSource } from "store/slices/settings-slice";
import toast from "react-hot-toast";
import { HelpHover } from "common/components/helpButton";
import { SourceTypes } from "store/types/source-types";

interface Props {
    isLoadingEnabled: boolean
}
export const SourceSelector = ({ isLoadingEnabled }: Props) => {
    /**Constants */
    const dispatch = useDispatch();

    /**Selectors */
    const sourceId = useSelector((store: ApplicationState) => store.settingsReducer.sourceId);
    const sourceName = useSelector((store: ApplicationState) => store.settingsReducer.sourceName);

    /**State */
    const [listSource, setListSource] = useState<Array<any>>([]);
    const [source_selected, set_source] = useState<Object>({});
    const [isListLoading, setIsListLoading] = useState<boolean>(true);
    const [messageList, setMessageList] = useState<Array<string>>([]);

    // let messageList: Array<string> = []


    /** handlerOnChange */
    const handlerOnChangeSource = (selectedOption: any) => {
        if (selectedOption) {
            set_source(prevState => ({
                ...prevState,
                value: selectedOption.value,
                label: selectedOption.label
            }))
            dispatch(setSource({ sourceId: selectedOption.value, sourceName: selectedOption.label }))
            setHelpMessage(selectedOption.value);
        }
    }

    const loadSourceList = async () => {
        try {
            const response = await getAllSource()
            const options = response.data.map((s: any) => {
                return { value: s.id, label: s.name }
            })
            setListSource(options)
            set_source(prevState => ({
                ...prevState,
                value: options[2].value,
                label: options[2].label
            }))
            handlerOnChangeSource(options[2])
        } catch {
            toast.error("Failed to load Source list. Please try again.");
        } finally {
            setIsListLoading(false)
        }
    }

    function setHelpMessage(sourceId: number) {
        switch (sourceId) {
            case SourceTypes.NRG_STREAM:
                setMessageList(["NRG Stream:",
                    "Timezone is the local prevailing time of the ISO.",
                    "NRG data is stored as Hour Starting (hrs 0-23)",
                    "When 'Hourly' interval is selected for a Real-Time (5min) Price, the value is the average of the 5min prices for that hour.  It is not a point-in-time value.",
                    "Get Price: 5min data is limited to 5 years, no limit on Hourly data.",
                    "The Start Date is the earliest date in NRG Stream.  The End Date is the current day, but that doesn't mean there is data till then.",
                    `Searching LMPs is a wildcard search.  If search is wraped in quotes (e.g. "South"), then it will be an exact search.`,
                    `Searching LMPs filters by name, location, and neartest substation name.`
                ])
                break;
            case SourceTypes.AYPA_NODAL:
                setMessageList(["Aypa Nodal:",
                    "Timezone is the local prevailing time of the Region.",
                    "Forecasted data is stored in Hour Ending (hrs 1-24).  The hours are shifted back by 1.  e.g. price of $25 for hour 24 is $25 for hour 23.",
                    `Searching Nodes is a wildcard search.  If search is wraped in quotes (e.g. "South"), then it will be an exact search.`,
                    `Searching Nodes filters by name, bus number, and neartest substation name.`
                ])
                break;
            case SourceTypes.AYPA_ZONAL:
                setMessageList(["Aypa Zonal:",
                    "Timezone is the local standard time of the Region.",
                    "Forecasted data is stored in Hour Ending (hrs 1-24).  The hours are shifted back by 1.  e.g. price of $25 for hour 24 is $25 for hour 23.",
                ])
                break;
            case SourceTypes.WOOD_MAC:
                setMessageList(["Wood Mac:",
                    "Timezone is the local standard time of the Region.",
                    "Price data is stored in Hour Starting (hrs 0-23).",
                ])
                break;
            default:
                setMessageList(["NRG Stream:",
                    "Timezone is the local prevailing time of the ISO.",
                    "NRG data is stored as Hour Starting (hrs 0-23)",
                    "When 'Hourly' interval is selected for a Real-Time (5min) Price, the value is the average of the 5min prices for that hour.  It is not a point-in-time value.",
                    "Get Price: 5min data is limited to 5 years, no limit on Hourly data.",
                    "The Start Date is the earliest date in NRG Stream.  The End Date is the current day, but that doesn't mean there is data till then.",
                ])
                break;
        }
    }

    useEffect(() => {
        loadSourceList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <DivContainer>
                <DivContainer100 label={"Source"} help={<HelpHover messageList={messageList} largeMarg={true}></HelpHover>}>
                    <Select
                        id="sourceDropdown" // for test cases
                        onChange={handlerOnChangeSource}
                        options={listSource}
                        value={source_selected ? { value: sourceId, label: sourceName } : null}
                        className='react-select-container'
                        classNamePrefix="react-select"
                        menuPlacement='auto'
                        isDisabled={isLoadingEnabled}
                        isLoading={isListLoading}
                    />
                </DivContainer100>
            </DivContainer>
        </>
    )
}

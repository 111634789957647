import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Accordion, Card } from "react-bootstrap";

import { CardProps } from "common/interfaces/cardProps";
import { CardTitle } from "../../../../common/components/accordionCard/cardTitle";
import classes from './../style.module.scss';
import { BulkCustomToggle } from "../components/customToggle";
import { Loading } from "common/components/loading";
import { SourceTypes } from "store/types/source-types";
import { wsDisconnect } from "store/slices/websocket-slice";
import config from 'config';
import { requestFailedBulk } from "store/slices/bulk-slice";
import { useInitRunBulkTb } from "../hooks/useInitRunBulkTb";
import { useExecuteBulkTb } from "../hooks/useExecuteBulkTb";
import { useEffect, useState } from "react";
import { TopBottomTableTabs } from "./topBottomTable/topBottomTableTabs";


export const BulkNodeResult = ({ eventKey, stack, checkAypaToken }: CardProps) => {

    const dispatch = useDispatch();
    const timeoutTime = 1800000; //3min update error message to match
    
    /**SELECTORS */
    const sourceId = useSelector((store: ApplicationState) => store.settingsReducer.sourceId);
    const activeCard = useSelector((store: ApplicationState) => store.bulkReducer.activeCard);
    const isLoadingData = useSelector((store: ApplicationState) => store.bulkReducer.isLoadingData);
    const errorMessage = useSelector((store: ApplicationState) => store.bulkReducer.errorMessage);
    const executeRunTb = useSelector((store: ApplicationState) => store.bulkReducer.executeRunTb);
    const completedBulkList = useSelector((store: ApplicationState) => store.bulkReducer.completedBulkList);
    const bulkPriceRequests = useSelector((store: ApplicationState) => store.bulkReducer.bulkPriceRequests);

    const [timeOutId, setTimeOutId] = useState<any>(null); 
    const [completeStatus, setCompleteStatus] = useState<number>(0); 

    useInitRunBulkTb(checkAypaToken);

    useExecuteBulkTb();

    useEffect(() => {
        if (executeRunTb) {
            if (timeOutId) {
                clearTimeout(timeOutId)
                setTimeOutId(null);
            }

            const timeOut = setTimeout(() => {
                dispatch(requestFailedBulk({"message": "Request timed out.", "details": "Request took longer than 3mins."}));
            }, timeoutTime);
            setTimeOutId(timeOut)
        } else {
            setTimeOutId(null);
            clearTimeout(timeOutId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeRunTb]);
    
    useEffect(() => {
        const completed = Object.keys(completedBulkList).length;
        if (completed > 0 && bulkPriceRequests > 0) {
            setCompleteStatus(parseFloat((completed/bulkPriceRequests).toFixed(2))*100);
        } else {
            setCompleteStatus(0);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completedBulkList]);

    function cancelRequest() {
        if (sourceId === SourceTypes.NRG_STREAM) {
            dispatch(wsDisconnect(config.fixedScheduleWs))
        }
        setTimeout(() => {
            dispatch(requestFailedBulk(null));
        }, 0);
    }

    const loadingMessage =
    <p>
        <b>Processing Data {completeStatus}%</b>
        <br/>
        This may take up to 10 minutes...
    </p>

    return (
        <Card className={classes.card}>
            <BulkCustomToggle as={Card.Header} eventKey={eventKey} stack={stack}>
                <CardTitle title='Results' eventKey={activeCard} order={eventKey} />
                {activeCard === '2' &&
                    <>
                        Download Menu 111
                    </>
                }
            </BulkCustomToggle>
            <Accordion.Collapse eventKey={eventKey}>
                <Card.Body className={`${classes.body} scrollable`}>
                    <Loading isLoading={isLoadingData} errorMessage={errorMessage} showButtonCancel={true}  loadingMessage={loadingMessage} callback={() => cancelRequest()}>
                        <TopBottomTableTabs></TopBottomTableTabs>
                    </Loading>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

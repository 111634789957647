import config from 'config';
import {api} from './api';

export const getAllPriceMetadataByNode = async (nodeId: string) => {
    let params = new URLSearchParams();
    params.append("node_id", nodeId);
    
    api.defaults.baseURL = config.baseUrl;
    return api.get(`stream-metadata/`, { params });
}

/**
 * Fetches all price streams based on the params passed. This is used by Node component in isoNode.
 * @param isoId - The ID of the ISO/region.
 * @param nodeName - Optional node name for filtering.
 * @param locationId - Optional locationId for filtering.
 * @param substationId - Optional substationId for filtering.
 * @param priceType - Optional priceType hour/5min for filtering.
 * @param active - Optional display active nodes for filtering.
 * @param offset - Optional offset for paginated results.
 * @returns Array of all nodes with node id and node name.
 */
export const getAllStreamMetadataByFilter = (isoId: number, nodeName: string | null, locationId: number | null, substationId: number | null, priceType: string | null, active: boolean | null, offset?: number) => {
    let params = new URLSearchParams();
    params.append("limit", '100');
    params.append("region_id", `${isoId}`)
    if (offset) {
        params.append("offset", `${offset}`)
    } else {
        params.append("offset", '0');
    }
    if (nodeName && nodeName.trim() !== '') {
        params.append("node_name", nodeName.trim());
    }
    if (locationId) {
        params.append("location_id", `${locationId}`);
    }
    if (substationId) {
        params.append("substation_id", `${substationId}`);
    }
    if (priceType) {
        params.append("price_type", `${priceType}`);
    }
    // either getting active nodes, or all nodes(inactive/active)
    let isNodeDisabled = null;
    if (active === true) {
        isNodeDisabled = false;
    }
    if (active === false || active === true) {
        params.append("node_disabled", `${isNodeDisabled}`);
    }
    api.defaults.baseURL = config.baseUrl;
    return api.get(`stream-metadata/bulk/`, { params });
}
import { ApplicationState } from 'store';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import { PriceOption } from 'common/components/selectComponents';
import { setPrice } from 'store/slices/settings-slice';
import { getLocalizedDate } from 'common/utils/utils';
import { getAllPriceMetadataByNode } from 'common/adapters/priceMetadata';
import toast from 'react-hot-toast';

interface Props {
    isLoadingEnabled: boolean
}
export const Price = ({ isLoadingEnabled }: Props) => {
    /**Constants */
    let selectRef: any = null;
    const dispatch = useDispatch();

    /**Selectors */
    const nodeIdSelected = useSelector((store: ApplicationState) => store.settingsReducer.nodeId);
    /**States */
    const [pricesMetadata, setPricesMetadata] = useState<Array<any>>([]);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const clearValue = () => {
        selectRef.clearValue();
        setPricesMetadata([]);
        const price = {
            priceId: null,
            priceMaxtimestamp: null,
            priceMintimestamp: null,
            streamId: null,
            granularity: null,
            name: null,
        }
        dispatch(setPrice(price));
    };

    const handlerOnChangePrice = (selectedOption: any, action: any) => {
        if (action.action !== 'clear') {
            const price = {
                priceId: selectedOption.value,
                priceMaxtimestamp: selectedOption.max_timestamp,
                priceMintimestamp: selectedOption.min_timestamp,
                streamId: selectedOption.streamId,
                granularity: selectedOption.granularity === "FIVEMIN" ? 'FIVEMIN_HOURLY' : selectedOption.granularity,
                name: selectedOption.label
            }
            dispatch(setPrice(price));
        }
    }

    // const onCompletePriceMetadata = ({ data }: any) => {
    //     setPricesMetadata(data)
    // }

    const formatPriceMetada = (data: Array<any>) => {
        return data ? data.map((s: any) => (
            {
                value: s.id,
                label: s.name,
                min_timestamp: getLocalizedDate(s.min_timestamp, "", false),
                max_timestamp: getLocalizedDate(s.max_timestamp, "", false),
                granularity: s.granularity,
                price_type: s.price_type,
                streamId: s.stream_id
            })) : data;
    }

    const loadPriceMetadata = async (nodeIdSelected: string) => {
        try {
            const response = await getAllPriceMetadataByNode(nodeIdSelected)
            const data = formatPriceMetada(response?.data);
            setPricesMetadata(data)
        } catch {
            setPricesMetadata([])
            toast.error("Failed to load the ISO list. Please try again.");
        }
    }

    useEffect(() => {

        if (nodeIdSelected) {
            setIsDisabled(false);
            // dispatch({ type: SettingTypes.FETCH_PRICE_METADATA, payload: nodeIdSelected, onComplete: onCompletePriceMetadata });
            loadPriceMetadata(nodeIdSelected.toString())

        } else {
            setIsDisabled(true);
        }
        clearValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodeIdSelected]);

    return (
        <Select
            ref={ref => {
                selectRef = ref;
            }}
            options={pricesMetadata}
            onChange={handlerOnChangePrice}
            className='react-select-container'
            classNamePrefix="react-select"
            components={{ Option: PriceOption }}
            isDisabled={isDisabled || isLoadingEnabled}
            isLoading={pricesMetadata.length === 0 && !isDisabled}
        />
    )
}

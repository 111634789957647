import { generateRandomNumberAsString } from "common/utils/utils";

interface Props {
    title: string,
    disabled: boolean,
    onClick: () => void,
    primary: string
}
function DefaultButton ({ title, disabled, onClick, primary}: Props) {
    const idNum = generateRandomNumberAsString(5) + "button"
    let className = "";
    switch (primary) {
        case "primary":
            break;
        case "secondary":
            className = "secondary";
            break;
        case "warning":
            className = "warning";
            break;
        default:
            break;
    }
    return <button className={className} id={idNum} disabled={disabled} onClick={onClick}>{title}</button>;
}

export default DefaultButton;

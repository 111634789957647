import { getAllLocationByFilter } from "common/adapters/entities"
import { setLocationId } from "store/slices/settings-slice"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import { ApplicationState } from "store"
import toast from 'react-hot-toast';



interface Props {
    isLoadingEnabled: boolean
}

export const Location = ({ isLoadingEnabled }: Props) => {

    const dispatch = useDispatch()

    const sourceId = useSelector((store: ApplicationState) => store.settingsReducer.sourceId);
    const regionId = useSelector((store: ApplicationState) => store.settingsReducer.regionId);
    const forecastId = useSelector((store: ApplicationState) => store.settingsReducer.forecastId);
    const nodeIdSelected = useSelector((store: ApplicationState) => store.settingsReducer.nodeId);
    const locationId = useSelector((store: ApplicationState) => store.settingsReducer.locationId);

    const [listLocation, setListLocation] = useState<Array<any>>([]);
    const [locationSelected, setLocationSelected] = useState<Array<any>>([]);
    const [isListLoading, setListLoading] = useState<boolean>(true);

    const handlerOnChangeLocation = (selectedOption: any): void => {
        setLocationSelected(selectedOption)
        dispatch(setLocationId(selectedOption ? { locationId: selectedOption.value, locationName: selectedOption.label } : { locationId: null, locationName: null }))
    }

    const loadLocationList = async () => {

        try {
            const currentRegionId = regionId;
            const currentForecastId = forecastId;
            const response = await getAllLocationByFilter(sourceId, currentRegionId, currentForecastId);

            // Check if the currentRegionId and currentForecastId are still the latest values
            if (currentRegionId === regionId && currentForecastId === forecastId) {
                const options = response.data.map((s: any) => {
                    return { value: s.id, label: s.name };
                });
                if (options.length !== 0) { // Cross check and remove if redundant
                    setListLocation(options)
                }
            }
        } catch {
            setListLocation([])
            toast.error("Failed to load the Location list. Please try again.");
        } finally {
            setListLoading(false)
        }
    };

    useEffect(() => {
        if (regionId !== null || forecastId !== null) {
            loadLocationList()
        }
        setLocationSelected([])
        dispatch(setLocationId({ locationId: null, locationName: null }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regionId, forecastId]) // Remove regionId since locaiton is not enabled until forecast is selected

    useEffect(() => {
        const option = listLocation.filter(item => item.value === locationId);
        setLocationSelected(option)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodeIdSelected])

    return (
        <Select
            id="locationDropdown"
            isClearable
            onChange={handlerOnChangeLocation}
            options={listLocation}
            value={locationSelected}
            className='react-select-container'
            classNamePrefix="react-select"
            menuPlacement='auto'
            isDisabled={isLoadingEnabled || !forecastId}
            isLoading={isListLoading}
        />
    )
}
import { ApplicationState } from "store";
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { setSimulateButton } from "store/slices/settings-slice";
import { cancelOperationTimeOut } from "store/slices/chart-slice";

export const useWaitFixedSchedule = () => {

    /**Constants */
    const dispatch = useDispatch();

    /**States */
    const [timeOutId, setTimeOutId] = useState<any>(null);

    /**Selectors */
    const executeOperation = useSelector((store: ApplicationState) => store.chartReducer.executeOperation);    

    useEffect(() => {
        if (executeOperation) {
            if (timeOutId) {
                clearTimeout(timeOutId)
                setTimeOutId(null);
            }

            const timeOut = setTimeout(() => {
                dispatch(cancelOperationTimeOut());
                dispatch(setSimulateButton(false));
            }, 120000);

            setTimeOutId(timeOut)
        }

        if (!executeOperation) {
            setTimeOutId(null);
            clearTimeout(timeOutId);
        }

        return () => {
            if (timeOutId) {
                setTimeOutId(null);
                clearTimeout(timeOutId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeOperation]);
}

import { ApplicationState } from "store";
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";

import { setGetPriceButton, setPriceData, setPriceRawData } from "store/slices/settings-slice";
import { requestFailed } from "store/slices/prices-slice";
import { SourceTypes } from "store/types/source-types";
import { formatMeanPrices, formatRawPrices } from "store/sagas/mean-price-sagas";
import { successOperationPrice } from "store/slices/prices-slice";

export const useExecutePriceLookup = () => {
    // TODO Handle timeout
    /**Contants */
    const dispatch = useDispatch();

    /**Selectors */
    const pricesReducer = useSelector((store: ApplicationState) => store.pricesReducer);
    const priceList = useSelector((store: ApplicationState) => store.settingsReducer.priceList);

    function processAllData() {
        for (const key in pricesReducer.loadedDataList) {
            const priceData = pricesReducer.loadedDataList[key];
            // find matching priceList - has request
            let matchingRequest = null;
            for (const req of priceList) {
                if (req.eventId === key) {
                    matchingRequest = req;
                    break;
                }
            }
            if (matchingRequest) {
                if (matchingRequest.sourceId === SourceTypes.NRG_STREAM) {
                    processWebSocketData(priceData, matchingRequest.eventId);
                } else {
                    processRestData(priceData, matchingRequest.eventId);
                }
            }
        }

    }

    function processWebSocketData(priceData: any, eventId: string) {
        let priceJson = null;
        try {
            priceJson = JSON.parse(priceData)
        } catch (e) {
            console.error(`Error parsing : ${e}`)
        }
        if (priceJson) {
                        // error handling is in websocket-saga.ts
            responseWithoutError(priceJson, eventId);
        } else {
            responseDoneWithError({ "error": { "message": "Unable to parse data", "details": "JSON.parse() failed" } })
        }
    }

    function processRestData(priceData: any, eventId: string) {
        // REST data does not need to be json parsed
        // error handling in mean-price-saga.ts
        responseWithoutError(priceData, eventId);
    }

    const responseDoneWithError = (data: any) => {
        const message = data.error.message;
        const details = data.error.details;
        dispatch(requestFailed({ message, details }));
        dispatch(setGetPriceButton(false));
    }

    const responseWithoutError = (data: any, eventId: string) => {
        // convert data and save
        if ("data" in data) {
            dispatch(setPriceRawData({data:formatRawPrices(data), key:data["key"], eventId: eventId}));
            dispatch(successOperationPrice(data));
        } else {
            dispatch(setPriceData({data:formatMeanPrices(data), key:data["key"], eventId: eventId}));
            dispatch(successOperationPrice(data));
        }
        
    }

    useEffect(() => {
        const completedLen = Object.keys(pricesReducer.completedList).length;
        if (completedLen > 0 && completedLen === priceList.length &&
            Object.values(pricesReducer.completedList).every((value) => value === true)) {
            // completed requests match requested length and all are true
            processAllData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricesReducer.completedList]);

}

import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { PriceProfileChartWrapper } from "./../priceProfileChartWrapper";
import classes from "./../../style.module.scss";
import { setDisplay } from "store/slices/settings-slice";
import { PriceRawChartWrapper } from "../priceRawChartWrapper";
import { ApplicationState } from "store";

export const PriceProfileTabs = (props: any) => {
    /**Constants */
    const dispatch = useDispatch();
    const priceTab = useSelector((store: ApplicationState) => store.settingsReducer.priceTab);

    /**State */
    const [key, setKey] = useState(priceTab);
    const group = "hour"

    const setKeyHandler = (newkey: any) => {
        if (newkey === key) return false;
        dispatch(setDisplay(newkey));
        setKey(newkey);
    }

    return (
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k: any) => setKeyHandler(k)}
            justify
        >
            <Tab eventKey="overall" title="Overall">
                {key === 'overall' &&
                    <div className={`${classes.tabContent} ${classes.oneChart}`}>
                        <PriceProfileChartWrapper group={[group]} responseGroupingProp='' />
                    </div>
                }
            </Tab>

            <Tab eventKey="monthly" title="Monthly">
                {key === 'monthly' &&
                    <div className={`${classes.tabContent} ${classes.oneChart}`}>
                        <PriceProfileChartWrapper group={[group, 'month']} responseGroupingProp='month' />
                    </div>
                }
            </Tab>

            <Tab eventKey="wint_sum" title="Seasonally">
                {key === 'wint_sum' &&
                    <div className={`${classes.tabContent} ${classes.oneChart}`}>
                        <PriceProfileChartWrapper group={[group, 'wint_sum']} responseGroupingProp='wint_sum' />
                    </div>
                }
            </Tab>

            <Tab eventKey="yearly" title="Yearly">
                {key === 'yearly' &&
                    <div className={`${classes.tabContent} ${classes.oneChart}`}>
                        <PriceProfileChartWrapper group={[group, 'year']} responseGroupingProp='year' />
                    </div>
                }
            </Tab>

            <Tab eventKey="wint_sum_by_year" title="Season by Year">
                {key === 'wint_sum_by_year' &&
                    <div className={`${classes.tabContent} ${classes.multiOneChart}`}>
                        <PriceProfileChartWrapper group={[group, 'year', 'wint_sum']} responseGroupingProp='year' />
                    </div>
                }
            </Tab>

            <Tab eventKey="month_by_year" title="Month by Year">
                {key === 'month_by_year' &&
                    <div className={`${classes.tabContent} ${classes.multiOneChart}`}>
                        <PriceProfileChartWrapper group={[group, 'year', 'month']} responseGroupingProp='year' />
                    </div>
                }
            </Tab>
            <Tab eventKey="raw" title="Raw">
                {key === 'raw' &&
                    <div className={`${classes.tabContent} ${classes.oneChart}`}>
                        <PriceRawChartWrapper/>
                    </div>
                }
            </Tab>
        </Tabs>
    )
}

import { useEffect } from "react"
import { ApplicationState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { wsConnect, wsSendMessage } from "store/actions/websocket-action";
import config from 'config';
import { addDayToDateStr, formatDate, getLocalizedDate } from "common/utils/utils";
/**
 * useInitRunBulkTb Hook
 * 
 * A React hook for initiating and managing the process of running bulk Top Bottom based on websocket connection conditions.
 *
 * @returns {void}
 */

export const useInitRunBulkTb = (checkAypaToken: Function) => {

    /**Constant */
    const dispatch = useDispatch();

    /**Selectors */
    const executeRunTb = useSelector((store: ApplicationState) => store.bulkReducer.executeRunTb);
    const bulkPriceList = useSelector((store: ApplicationState) => store.bulkReducer.bulkPriceList);
    const dateRange = useSelector((store: ApplicationState) => store.bulkReducer.dateRange);
    const roundTripEfficiency = useSelector((store: ApplicationState) => store.bulkReducer.roundTripEfficiency);
    const batteryHour = useSelector((store: ApplicationState) => store.bulkReducer.batteryHour);
    const batteryPower = useSelector((store: ApplicationState) => store.bulkReducer.batteryPower);
    const socketFixedSchedule = useSelector((store: ApplicationState) => store.websocketReducer.socketFixedSchedule);
      
    useEffect(() => {
        checkAypaToken();
        if (executeRunTb && !socketFixedSchedule) {
            dispatch(wsConnect(config.fixedScheduleWs));
        }
        if (executeRunTb && socketFixedSchedule && bulkPriceList.length > 0) {
            // common values
            const startDate = dateRange ? dateRange[0] : getLocalizedDate("", "", false);
            const endDate = dateRange ? dateRange[1] : getLocalizedDate("", "", false);
            const endDateStr = addDayToDateStr(endDate);
            const startDateFinal = formatDate(startDate);
            const endDateFinal = formatDate(endDateStr);
            // rre is number, backend needs percentage
            const rre = roundTripEfficiency ? roundTripEfficiency/100 : 0.88;
            for (const item of bulkPriceList) {
                if (item.streams.length > 0) {
                    for (const stream of item.streams) {
                        const parameter = {
                            action: "top-bottom",
                            event_id: stream.eventId,
                            rated_power: batteryPower,
                            duration: batteryHour,
                            roundtrip_efficiency: rre,
                            start_date: startDateFinal,
                            end_date: endDateFinal,
                            granularity: stream.granularity,
                            stream_id: stream.streamId,
                            iso: item.regionName,
                            forecast_location: null,
                            node_name: null
                        };
                        console.log('top-bottom ', parameter);
                        const jsonString: string = JSON.stringify(parameter);
                        dispatch(wsSendMessage(jsonString, config.fixedScheduleWs));
                    }
                } else {
                    // nodal / zonal data.
                    console.log('nodal/zonal data');
                }
            }
        }        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeRunTb, socketFixedSchedule]);

}
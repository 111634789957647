import classes from "./style.module.scss";

import sprites from "common/assets/img/sprites.svg";

interface Props {
    title: string,
    eventKey: string,
    order: string
}

export const CardTitle = ({ title, eventKey, order }: Props) => {
    const fontBold = { fontWeight: 'bold' };
    const activeStyle = eventKey === order ? { ...fontBold } : {};

    const way = order > eventKey ? 'up' : 'down';

    return (
        <div className={classes.card__title}>
            <span style={activeStyle}>{title}
                {
                    eventKey !== order &&
                    <svg className={way}>
                        <use xlinkHref={`${sprites}#icon-${way}`}></use>
                    </svg>
                }
            </span>
        </div>
    )
}

import DefaultButton from 'common/components/button';
import classes from './style.module.scss';
import { useState } from 'react';
import ConfirmationModal from 'common/components/modal';
import toast from 'react-hot-toast';
import { deleteForecast } from 'common/adapters/entities';
import { SourceSelector } from 'common/aypaForms/sourceSelector';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { SourceTypes } from 'store/types/source-types';
import { Nodal } from 'pages/home/settingsSection/nodal';
import { DivContainer } from 'common/components/divContainer';
import { Zonal } from 'pages/home/settingsSection/zonal';
import { WoodMac } from 'pages/home/settingsSection/woodMac';

export const DeleteForecastSection = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const settings = useSelector((store: ApplicationState) => store.settingsReducer);

    function onClick() {
        setIsModalOpen(true);
    }

    const handleConfirmation = async (confirmed: boolean) => {
        setIsModalOpen(false);
        if (confirmed && settings.forecastId && settings.sourceId) {
            try {
                console.log('deleting...')
                await deleteForecast(settings.forecastId, settings.sourceId);
            } catch (error) {
                console.error("failed to trigger delete forecast ", error)
                toast.error("Failed to trigger delete forecast. Please try again.");
            }
        }
      };

    return (
        <>
            <div className={classes.container}>
                <h2>Delete Forecast</h2>
                <p>
                    This button will trigger delete the selected forecast from the app.  It will delete the forecast from all Regions/Locations/Nodes
                </p>
                <SourceSelector isLoadingEnabled={false} />
                {settings.sourceId === SourceTypes.NRG_STREAM &&
                    <p>Cannot delete NRG Stream sources</p>
                }
                {settings.sourceId === SourceTypes.AYPA_ZONAL &&
                    (<>
                        <Zonal isLoadingEnabled={false} showLocation={false} />
                    </>)
                }
                {settings.sourceId === SourceTypes.AYPA_NODAL &&
                    (<>
                        <Nodal isLoadingEnabled={false} showLocationAndNode={false}/>
                    </>)
                }
                {settings.sourceId === SourceTypes.WOOD_MAC &&
                    (<>
                        <WoodMac isLoadingEnabled={false} showLocation={false}/>
                    </>)
                }
                <DivContainer>
                    <DefaultButton title="Delete Forecast" disabled={!settings.forecastId} onClick={onClick} primary="primary" ></DefaultButton>
                </DivContainer>
                <ConfirmationModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onConfirm={handleConfirmation}
                    text={"Are you sure you want to delete?"}
                ></ConfirmationModal>
            </div>
            
        </>
    )
}

import { ApplicationState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { SimulateChartTypes } from "store/types/simulate-chart-types";

export const useFixedScheduleSummary = () => {
    /**Constants */
    const dispatch = useDispatch();

    /**States */
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [points, setPoints] = useState<Array<any>>([]);
    const [errorMessage, setErrorMessage] = useState();

    /**Selectors */
    const executeFixedScheduleSummary = useSelector((store: ApplicationState) => store.chartReducer.executeFixedScheduleSummary);
    const executeFixedScheduleSummaryComplete = useSelector((store: ApplicationState) => store.chartReducer.executeFixedScheduleSummaryComplete);
    const executeFixedScheduleSummaryData = useSelector((store: ApplicationState) => store.chartReducer.executeFixedScheduleSummaryData);
    const executeFixedScheduleSummaryError = useSelector((store: ApplicationState) => store.chartReducer.executeFixedScheduleSummaryError);
    const fixedScheduleId = useSelector((store: ApplicationState) => store.chartReducer.fixedScheduleId);

    const onCompleteFixedScheduleSummary = () => {
        
        if (executeFixedScheduleSummaryError)
            setErrorMessage(executeFixedScheduleSummaryError);
        else
            setPoints(executeFixedScheduleSummaryData);

        setIsLoading(false);
    }

    const fetchFixedScheduleSummary = () => {
        setIsLoading(true);
        const fixedScheduleIdSelected = fixedScheduleId ?? "";
        // dispatch({ type: SimulateChartTypes.FETCH_FIXED_SCHEDULE_SUMMARY, fixedScheduleId: fixedScheduleIdSelected, onComplete: onCompleteFixedScheduleSummary });
        dispatch({ type: SimulateChartTypes.FETCH_FIXED_SCHEDULE_SUMMARY, fixedScheduleId: fixedScheduleIdSelected});
    }

    useEffect(() => {
        if (executeFixedScheduleSummary) {
            fetchFixedScheduleSummary();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeFixedScheduleSummary]);

    useEffect(() => {
        if (executeFixedScheduleSummaryComplete) {
            onCompleteFixedScheduleSummary();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeFixedScheduleSummaryComplete]);

    return { isLoading, errorMessage, points };
}

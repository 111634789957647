import { createSlice } from '@reduxjs/toolkit'
import { NodeResults } from 'common/interfaces/topBottomData';
import { BulkState } from 'store/states/bulk-state';

export const INITAL_BATTERY_HOUR = 4;

const initialState: BulkState = {
    activeCard: '0',
    regionId: null,
    regionName: null,
    locationId: null,
    locationName: null,
    substationId: null,
    substationName: null,
    nodeInputValue: null,
    priceType: null,
    nodeActive: true,
    nodeOffset: 0,
    bulkPriceList: [],
    bulkPriceRequests: 0,
    priceMaxtimestamp: null,
    priceMintimestamp: null,
    dateRange: null,
    getPriceListButtonClicked: false,
    tbButtonClicked: false,
    errorMessage: null,
    isLoadingData: false,
    batteryHour: 4,
    batteryPower: 100,
    roundTripEfficiency: 88,
    executeRunTb: false,
    loadedBulkList: {},
    completedBulkList: {},
    topBottomSummaryData: {}

}

export const bulkSlice = createSlice({
    name: 'bulk',
    initialState,
    reducers: {
        setBulkActiveCard(state, action) {
            // on card change (Profile, Chart, Tables)
            state.activeCard = action.payload
        },
        setSearchEntites(state, action) {
            state.regionId = action.payload.regionId;
            state.regionName = action.payload.regionName;
            state.locationId = action.payload.locationId;
            state.locationName = action.payload.locationName;
            state.substationId = action.payload.substationId;
            state.substationName = action.payload.substationName;
            state.nodeInputValue = action.payload.nodeInputValue;
            state.priceType = null;
            state.nodeActive = action.payload.nodeActive;
            state.nodeOffset = 0;
            state.getPriceListButtonClicked = true;
            state.isLoadingData = true;
            state.activeCard = '0';
        },
        requestSucceedsBulk(state, action) {
            state.isLoadingData = false;
            state.errorMessage = null;
            state.getPriceListButtonClicked = false;
            state.nodeOffset += action.payload;
        },
        requestFailedBulk(state, action) {
            // websocket or rest failed
            state.isLoadingData = false;
            state.errorMessage = action.payload;
            state.getPriceListButtonClicked = false;
            state.tbButtonClicked = false;
            state.completedBulkList = {};
            state.loadedBulkList = {};
            state.executeRunTb = false;
        },
        setGetPriceListButton(state, action) {
            // on Get Price button click
            // on any error in the price lookup process
            state.getPriceListButtonClicked = action.payload;
            state.activeCard = '0';
        },
        setTbSettings(state, action) {
            state.dateRange = action.payload.dateRange;
            state.batteryHour = action.payload.batteryHour;
            state.batteryPower = action.payload.batteryPower;
            state.roundTripEfficiency = action.payload.roundTripEfficiency;
            state.tbButtonClicked = true;
            state.isLoadingData = true;
            state.errorMessage = null;
            state.executeRunTb = true;
            state.completedBulkList = {};
            state.loadedBulkList = {};
            state.activeCard = '1';
        },
        setBulkPriceList(state, action) {
            // TODO update name to be specific for NRG
            const list: Array<NodeResults> = action.payload;
            let count = 0;
            for (const node of list) {
                if (node.streams.length > 0) {
                    count += node.streams.length;
                } else {
                    count +=1;
                }
            }
            state.bulkPriceList = action.payload;
            state.bulkPriceRequests = count;
        },
        successRunTb(state, action) {
            // on success message returned that is not COMPLETED - append data
            if (action.payload.event_id in state.loadedBulkList) {
                state.loadedBulkList[action.payload.event_id] += action.payload.data;
            } else {
                state.loadedBulkList[action.payload.event_id] = action.payload.data;
            }
        },
        requestBulkCompleted(state, action) {
            // once COMPLETE is returned from WebSocket for top-bottom.
            state.completedBulkList[action.payload] = true;
        },
        requestBulkCompleteAll(state) {
            state.executeRunTb = false;
            state.isLoadingData = false;
            state.completedBulkList = {};
            state.loadedBulkList = {};
        },
        setTbData(state, action) {
            // after price lookup completed, set data
            state.topBottomSummaryData[action.payload.eventId] = action.payload.data;
        },
        setIsLoadingData(state, action) {
            state.isLoadingData = action.payload;
        }

    },
});

export const { setBulkActiveCard, setSearchEntites, requestFailedBulk, setGetPriceListButton, requestSucceedsBulk,
        setTbSettings, setBulkPriceList, successRunTb, requestBulkCompleted, requestBulkCompleteAll, setTbData,
        setIsLoadingData
    } = bulkSlice.actions;

export default bulkSlice.reducer;